// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: var(--blanco),
    contrast: (
        500: rgba(0, 0, 0, 0.87)
    )
);

$fuse-black: (
    500: black,
    contrast: (
        500: var(--blanco),
    )
);

$fuse-navy: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    A700: #2D323E,
    contrast: (
        50: rgba(0, 0, 0, 0.87),
        100: rgba(0, 0, 0, 0.87),
        200: rgba(0, 0, 0, 0.87),
        300: var(--blanco),
        400: var(--blanco),
        500: var(--blanco),
        600: var(--blanco),
        700: var(--blanco),
        800: var(--blanco),
        900: var(--blanco),
        A100: rgba(0, 0, 0, 0.87),
        A200: var(--blanco),
        A400: var(--blanco),
        A700: var(--blanco),
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
    $font-family: 'Ubuntu, Muli, Helvetica Neue, Arial, sans-serif',
    $headline-6: mat.define-typography-level(20px, 32px, 600),
    $subtitle-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $body-1: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!
);

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "/src/app/layout/components/chat-panel/chat-panel.theme";
@import "/src/app/layout/components/toolbar/toolbar.theme";

@import "/src/app/main/authentication/login/login.theme";
@import "/src/app/main/authentication/free-trial/free-trial.theme";
@import '/src/app/main/authentication/renew-license/renew-license.theme';
@import '/src/app/main/authentication/initial-registration/initial-registration.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {

    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);

    //pages
    @include login-theme($theme);
    @include freeTrial-theme($theme);
    @include renewLlicense-theme($theme);
    @include initialRegistration-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
//$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);
$theme: mat.define-light-theme((color: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
    ), typography: $typography));

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme((color: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
    ), typography: $typography));

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {

    // Generate the Angular Material theme
    @include mat.all-component-colors($yellow-light-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);

    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
//$blue-gray-dark-theme: mat.define-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);
$blue-gray-dark-theme: mat.define-dark-theme((color: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
    ), typography: $typography));

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {

    // Generate the Angular Material theme
    @include mat.all-component-colors($blue-gray-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);

    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
//$pink-dark-theme: mat.define-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);
$pink-dark-theme: mat.define-dark-theme((color: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
    ), typography: $typography));

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {

    // Generate the Angular Material theme
    @include mat.all-component-colors($pink-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);

    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}