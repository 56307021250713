//imports
@import "walinwa.variables.scss";

// walinwa app

// common

* {
    box-sizing: border-box !important;
    position: static;
    width: auto;
    height: auto;
}

[hidden] {
    display: none !important;
}

.fa,
.far,
.fas {
    font-family: "Font Awesome 5 Free" !important;
}

// constants

:root {
    --blanco: #ffffff;
    --verde: #6ecb0b;
    --morado: #863b99;
    --gris: #5e767d;
    --grisClaro: #e6e6e6;
    --grisClaroTexto: #bfbfbf;
    --verdeAzulado: #3bb39c;
    --moradoClaro: #b061c3;
    --fucsia: #ff0194;
    --azul: #0ca1f1;
    --naranja: #ff9100;
    --amarillo: #ffc000;
    --azulTexto: #086fa8;
    --negro: #000000;
    // --font-size: .75vmax;
    --font-size: 16px;
    --colorText: #484F54;
    --grisCalendario: rgba(94, 118, 125, 0.2);
    --grisEnunciadoEjercicios: rgba(94, 118, 125, 0.8);
    --grisPestanas: rgba(94, 118, 125, 0.5);
    --azulFondo: rgba(12, 161, 241, 0.2);
    --naranjaTablas: rgba(255, 145, 0, 0.2);
}

html {
    font-size: var(--font-size);
}

body:not(.is-mobile) ::-webkit-scrollbar {
    font-size: var(--font-size);
    width: 1em !important;
    height: 1em !important;
}

// lets get started

.underline {
    text-decoration: underline;
}

.border-bottom-red {
    border-bottom: calc(var(--font-size) * 0.1) solid #ff0000;
}

.bg-red {
    background-color: rgba(255, 0, 0, 0.25);
}

.content-dialog {
    font-size: var(--font-size);
    //margin-top: -1em;
    height: 100%;
    position: relative;

    select {
        width: 100%;
        // font-size: calc(var(--font-size) * 1.25) !important;
        color: var(--azul);
        padding: 0.11em;

        option {
            color: var(--gris);
        }
    }

    button {
        // font-size: calc(var(--font-size) * 1.3) !important;
    }

    .close-icon {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        cursor: pointer;
        height: 2.5em;
        z-index: 1;
        font-size: var(--font-size);
    }
}

mat-select {
    .mat-mdc-select-arrow {
        color: inherit !important;
    }
}

input.mat-datepicker-input {
    /*margin-top: 5px !important;
    margin-bottom: 4px !important;*/
    margin-top: 3px !important;
    margin-bottom: 2px !important;
}

.mat-datepicker-toggle {
    display: inline-block !important;
    width: 40px !important;

    button {
        padding-right: 0px !important;

        .mat-datepicker-toggle-default-icon {
            width: 16px !important;
        }

        .mat-mdc-button-persistent-ripple {
            opacity: 0 !important;
        }
    }
}

.content-dialog {
    background-color: var(--azul) !important;
}

.content-dialog.white {
    background-color: var(--blanco) !important;

    h1 {
        color: var(--azul) !important;
        margin-top: -1em !important;
    }

    .mat-mdc-dialog-content p {
        color: var(--gris) !important;
    }

    p {
        color: var(--gris) !important;
    }

    .mat-mdc-dialog-actions {
        //justify-content: space-between !important;
        //width: 100%;
        justify-content: flex-end !important;
        padding: 25px 25px 25px 25px !important;

        button {
            background-color: var(--azul) !important;
            color: var(--blanco) !important;
            //max-width: 50% !important;
            white-space: unset;
            height: unset !important;
            min-height: 1.8em !important;
        }
    }
}

.walinwa-app.dev {

    #toolBar,
    .walinwa-header {
        background-color: var(--azul);
        //background-color: #086fa8;
    }
}

.walinwa-app {
    width: 100%;

    * {
        letter-spacing: 0.01em !important;
    }

    .hidden {
        display: none !important;
    }

    #walinwa-old-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        z-index: 1;
    }

    .walinwa-button {
        border-radius: 5em;
        box-shadow: none !important;
        // font-size: calc(var(--font-size) * 1.3) !important;
        min-width: auto !important;
        width: 12em !important;
        height: 1.8em !important;
        border: 0;
        color: var(--blanco);
        background-color: var(--azul);
        cursor: pointer;
    }

    .walinwa-button.walinwa-button-coin {
        width: 15em !important;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Ubuntu";

        img {
            margin-top: -1em;
            margin-left: -0.5em;
            margin-right: 0.5em;
        }
    }

    .walinwa-button.walinwa-button-gold {
        background-color: var(--amarillo);

        &:disabled {
            background-color: var(--gris) !important;
        }
    }

    .walinwa-coin {
        position: fixed;
        z-index: 1;
        font-size: var(--font-size);
        width: 2em;
        height: 2em;
        transition: 1s;
    }

    .walinwa-overlay-loading {
        font-size: var(--font-size);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 15;

        img {
            font-size: var(--font-size);
            width: 10em;
        }
    }

    .walinwa-100 {
        width: 100% !important;
    }

    .walinwa-h-100 {
        height: 100% !important;
    }

    .submit-button {
        position: relative;
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;

            mat-icon {
                position: static !important;
                margin: 0 0.1em 0 0.5em !important;
            }
        }
    }

    select:not(.walinwa-box-header-select) {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("https://walinwa.blob.core.windows.net/images/select-arrow-b.png");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 0.25em;
        background-size: auto 50%;
        padding-left: 0.5em;
    }

    mat-datepicker-toggle {
        top: 25% !important;
    }

    .walinwa-box-modal-dictation {
        tooltip {
            img {
                font-size: var(--font-size);
                height: 2em;
            }
        }
    }

    #stateSession .walinwa-tooltip-panel {
        width: 38em;
    }

    #stateSession .walinwa-tooltip-button {
        height: calc(var(--font-size) * 2);
    }

    #stateSession .walinwa-tooltip-panel-right {
        left: 200%;
        top: -70%;
    }
    tooltip.line-height {
        line-height: 2.5;
    }
    tooltip {
        position: relative;
        line-height: 2;

        sub {
            bottom: 0;
        }

        i {
            height: 1em;
            width: 1em;
            min-width: 1em;
            min-height: 1em;
            display: inline;
            vertical-align: middle;
            margin-right: 7px;
        }

        i.end {
            color: #329c45;
            // text-align: right;
            // margin: 0.5em 0.25em 0 0.25em;
            // margin-bottom: 0.5em;
            // font-size: calc(var(--font-size) * 1.2);
        }

        i.notDone {
            color: #ccc;
            // text-align: right;
            // margin-left: 1em;
            // margin: 0.25em;
            // border-radius: 50%;
            // margin-left: 0.75em;
            position: relative;
            // font-size: calc(var(--font-size) * 1.2);
        }

        i.notDone::after {
            content: "!";
            position: absolute;
            top: 0;
            right: 0;
            color: #ff0000;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1em;
        }

        i.notFinish {
            color: var(--naranja);
            // text-align: right;
            // margin: 0.25em;
            // margin-bottom: 0.5em;
            // font-size: calc(var(--font-size) * 1.2);
        }

        i.notRecover {
            color: var(--gris);
            // text-align: right;
            // margin-left: 0.75em;
            // font-size: calc(var(--font-size) * 1.2);
        }
        i.SkippedExercises  {
            color: var(--fucsia);
            // text-align: right;
            // margin-left: 0.75em;
            // font-size: calc(var(--font-size) * 1.2);
        }

        .walinwa-tooltip-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
        }

        .walinwa-tooltip-button {
            cursor: pointer;
            height: calc(var(--font-size) * 1.4);
            z-index: 2;
        }

        .walinwa-tooltip-panel-right {
            left: 260%;
            top: -115%;
        }

        .walinwa-tooltip-panel-right::before {
            content: "";
            font-size: var(--font-size);
            background: var(--blanco);
            width: 2em;
            height: 2em;
            position: absolute;
            top: 1em;
            left: -1em;
            transform: rotateZ(45deg);
            border: 0;
            border-left: 0.05em solid var(--azul);
            border-bottom: 0.05em solid var(--azul);
        }

        .walinwa-tooltip-panel-left {
            right: 200%;
            top: -115%;
        }

        .walinwa-tooltip-panel-left::before {
            content: "";
            font-size: var(--font-size);
            background: var(--blanco);
            width: 2em;
            height: 2em;
            position: absolute;
            top: 1em;
            right: -1em;
            transform: rotateZ(45deg);
            border: 0;
            border-right: 0.05em solid var(--azul);
            border-top: 0.05em solid var(--azul);
        }

        .walinwa-tooltip-panel {
            position: absolute;
            background: var(--blanco);
            font-size: var(--font-size);
            padding: 0.5em 1.5em;
            border: 0.05em solid var(--azul);
            min-height: calc(var(--font-size) * 4.5);
            min-width: calc(var(--font-size) * 10);
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: justify;
            z-index: 2;

            width: 25em;

            .walinwa-tooltip-info {
                // font-size: calc(var(--font-size) * 1.1);
                font-weight: normal;
                color: var(--gris);
                text-align: left;
            }
        }
    }

    .walinwa-tooltip {
        position: absolute;
        background: var(--blanco);
        font-size: var(--font-size);
        padding: 0.5em 1em;
        top: -22%;
        // height: 8em;
        height: auto;
        min-height: 8em;
        width: 100%;
        max-width: 280px;
        min-width: 200px;
        border: 0.05em solid var(--azul);
        text-align: left;
        z-index: 900;

        .walinwa-tooltip-info {
            // font-size: calc(var(--font-size) * 1.1);
            color: var(--gris);
        }

        .walinwa-tooltip-info-primary {
            color: var(--amarillo);
            // font-size: calc(var(--font-size) * 1.3);
            position: relative;
            z-index: 5;
        }

        .last-login {
            // font-size: calc(var(--font-size) * 0.52);
        }
    }

    .walinwa-tooltip-left {
        // left: -155%;
        right: 70%;
    }

    .walinwa-tooltip-top {
        top: 50px;
        z-index: 600;
    }

    .walinwa-tooltip-left::before {
        content: "";
        font-size: var(--font-size);
        background: var(--blanco);
        width: 2em;
        height: 2em;
        position: absolute;
        top: 1em;
        right: -1em;
        transform: rotateZ(45deg);
        border: 0;
        border-right: 0.05em solid var(--azul);
        border-top: 0.05em solid var(--azul);
    }

    .walinwa-tooltip-top::before {
        content: "";
        font-size: var(--font-size);
        background: var(--blanco);
        width: 2em;
        height: 2em;
        position: absolute;
        top: -1em;
        left: calc(50% - 10px);
        transform: rotateZ(45deg);
        border: 0;
        border-left: 0.05em solid var(--azul);
        border-top: 0.05em solid var(--azul);
        z-index: 5;
    }

    .walinwa-tooltip-right {
        right: -155%;
    }

    .tooltip-right-fix::before {
        z-index: 1 !important;
    }

    .tooltip-right-fix {
        position: absolute;
        background: var(--blanco);
        font-size: var(--font-size);
        padding: 0.5em 1em;
        top: 87%;
        left: 7%;
        height: 6.5em;
        width: 23em;
        border: 0.05em solid var(--azul);

        .tooltip {
            font-size: 1em;
        }
    }

    .walinwa-tooltip-right::before {
        content: "";
        font-size: var(--font-size);
        background: var(--blanco);
        width: 2em;
        height: 2em;
        position: absolute;
        top: 1em;
        left: -1em;
        transform: rotateZ(45deg);
        border: 0;
        z-index: -1;
        border-left: 0.05em solid var(--azul);
        border-bottom: 0.05em solid var(--azul);
    }

    .walinwa-box-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        // background-color: rgba(12, 161, 241, 0.5);
        z-index: 900;
        background-color: rgba(0, 0, 0, 0.2);

        .walinwa-box-modal-left {
            font-size: var(--font-size);
            padding: 1em;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 2em;
                cursor: pointer;
            }
        }

        .walinwa-box-modal-right {
            font-size: var(--font-size);
            padding: 1em;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 2em;
                cursor: pointer;
            }
        }

        .walinwa-help {
            background-color: var(--blanco);
            position: relative;
            font-size: var(--font-size);
            // width: 75em;
            // height: 48em;
            width: 75vw;
            height: 80vh;

            .walinwa-close-modal {
                // font-size: calc(var(--font-size) * 1.3);
                position: absolute;
                right: 0.75em;
                top: 0.5em;
                width: 1.4em;
                height: 1.4em;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .modal-back-image {
                font-size: var(--font-size);
                // height: 48.5em;
                height: 80vh;
                background-size: cover;
            }

            .paginationArea {
                position: absolute;
                bottom: calc(50% - 1.5em);
                justify-content: center;
                align-items: center;
                width: 3em;
                height: 3em;
                text-align: center;

                .pagination-button {
                    border: 0;
                    cursor: pointer;
                    background: transparent;
                    justify-content: center;
                    align-items: center;
                    width: 3em;
                    height: 3em;

                    i {
                        // font-size: calc(var(--font-size) * 3);
                        color: var(--gris);
                    }
                }

                .pagination-disabled {
                    opacity: 0.25;
                }
            }

            .leftSide {
                left: 0;
            }

            .rightSide {
                right: 0;
            }
        }

        .walinwa-report {
            width: 100%;
            height: 100%;
            background-color: var(--blanco);

            .walinwa-box-modal-header {
                // font-size: calc(var(--font-size) * 2.5);
                font-weight: bold;
                padding: 0 5%;
                height: calc((var(--font-size) * 2.5) * 2.5);
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--verde);
                color: var(--blanco);
                font-size: 160%;

                .walinwa-box-modal-header-icons {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: var(--font-size);

                    img {
                        height: 4.4em;
                        width: 4.4em;
                        // font-size: var(--font-size);
                        margin-left: 1em;
                        cursor: pointer;
                    }
                }
            }

            .walinwa-box-modal-container-loading {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 10em;
                }
            }

            .walinwa-box-modal-container {
                width: 100%;
                height: calc(100% - ((var(--font-size) * 2.5) * 2.5));
                display: flex;
                flex-direction: column;

                .walinwa-box-modal-buttons {
                    flex: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        margin: 1rem;
                    }
                }
            }
        }

        .walinwa-box-score {
            position: absolute;
            background-color: var(--blanco);
            font-size: var(--font-size);
            width: 35em;
            height: 30em;
            border: calc(var(--font-size) * 0.2) solid var(--verde);
            transition: 1s;
            display: flex;
            flex-direction: column;

            .walinwa-box-score-header {
                // font-size: calc(var(--font-size) * 1.75);
                padding: 1% 5%;
                height: calc((var(--font-size) * 2.5) * 2.5);
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--verde);
                color: var(--blanco);
                flex-direction: column;
            }

            .walinwa-box-score-container {
                padding: calc(var(--font-size) * 3) calc(var(--font-size) * 2);
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
        }

        .walinwa-box-score-hidden {
            margin-top: -30em;
        }

        .walinwa-box-modal {
            margin-top: -100%;
            background-color: var(--blanco);
            // font-size: var(--font-size);
            width: 75em;
            height: 50em;
            max-width: 95%;
            transition: 1s;
            display: flex;
            flex-direction: column;
            background-size: 100% 100%;

            .walinwa-box-modal-header {
                // font-size: calc(var(--font-size) * 2.5);
                font-weight: bold;
                padding: 0 5%;
                height: calc((var(--font-size) * 2.5) * 2.5);
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--verde);
                color: var(--blanco);

                .walinwa-box-modal-header-buttons {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .walinwa-box-modal-header-button {
                        height: 75% !important;
                        font-size: var(--font-size);
                        margin-left: 1em;
                        box-shadow: -0.05em 0.1em 0.2em rgb(0 0 0 / 80%);
                        border-radius: 100%;
                        cursor: pointer;
                    }
                }

                .walinwa-box-modal-header-button {
                    height: 60%;
                }

                .walinwa-box-modal-header-walinwos {
                    height: 40%;
                    font-size: var(--font-size);
                    border-radius: 2em;
                    display: -webkit-box;
                    display: flex;
                    align-items: center;
                    padding: 0 1.5em 0 1em;
                    background-color: var(--blanco);

                    img {
                        height: 130%;
                        margin-top: -10%;
                    }

                    span {
                        color: var(--azul);
                        font-size: 1.5em;
                        margin-left: 0.5em;
                        white-space: nowrap;
                    }
                }
            }

            .walinwa-box-modal-header.walinwa-box-modal-header-yellow {
                background-color: var(--amarillo);
            }

            .walinwa-box-modal-container {
                padding: calc(var(--font-size) * 3) calc(var(--font-size) * 4);
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
        }

        .walinwa-box-modal-show {
            margin-top: 0;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
    }

    .walinwa-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 9999;

        .walinwa-modal {
            width: 40em;
            max-width: 75vh;
            background: var(--blanco);
            border: 0.05em solid var(--verde);
            font-size: var(--font-size);
            min-height: auto;

            .walinwa-modal-header {
                // font-size: 2em;
                font-size: 140%;
                font-weight: bold;
                padding: 0.25em 5%;
                background: var(--verde);
                color: var(--blanco);
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .walinwa-modal-body {
                padding: 1em;
                position: relative;

                // .walinwa-row{
                //     min-height: auto !important;
                // }
                .messageText {
                    // font-size: calc(var(--font-size) * 1.2);
                    height: 20em;
                    overflow-y: auto;
                    color: var(--gris);
                    background-color: var(--blanco);
                }

                .logoMessageIcon {
                    position: absolute;
                    font-size: var(--font-size);
                    bottom: 2vh;
                    right: 2%;
                    height: 8vh;
                }
            }
        }

        .walinwa-modal2 {
            width: 95%;
            height: 90%;
            position: relative;
            font-size: var(--font-size);
            margin-bottom: -1.5em;

            .walinwa-modal2-close {
                position: absolute;
                font-size: var(--font-size);
                width: 3em;
                height: 3em;
                top: -3em;
                right: -2.5em;
                cursor: pointer;
                filter: invert(100%) brightness(2);
                padding: 10px;
            }

            .walinwa-tpv-iframe {
                width: 100%;
                height: 100%;
                border: 0;
                background-color: var(--blanco);
            }
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
        line-height: 1.3em;
        white-space: break-spaces;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field.mat-form-field-type-mat-select .mat-mdc-form-field-infix {
        flex-direction: column;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .form-field.mat-form-field-type-mat-select .mat-mdc-form-field-infix {
        padding: 0 !important;
    }

    .mat-mdc-select {        
        color: var(--colorText) !important;
        font-family: 'Ubuntu', Muli, Helvetica Neue, Arial, sans-serif !important;            
        font-size: var(--font-size) !important;        
        margin-top: 0.85em;
        padding: 8px !important;
    }

    mat-icon {
        height: calc(var(--font-size) * 2) !important;
        width: calc(var(--font-size) * 2) !important;
        // font-size: calc(var(--font-size) * 1.5) !important;
    }

    button {
        img {
            height: 100%;
        }
    }

    .transparent-button {
        background: transparent !important;
        color: var(--blanco) !important;
    }

    .wh100 {
        width: 100%;
        height: 100%;
    }

    .f-1 {
        flex: 1;
    }

    .m-r-1 {
        margin-right: 1em;
    }

    .d-flex {
        display: flex;
    }

    .justify-content-center {
        justify-content: center !important;
    }

    .justify-content-between {
        justify-content: space-between !important;
    }

    .align-items-center {
        align-items: center;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*.mat-mdc-checkbox-label {
        // font-size: calc(var(--font-size) * 1.25);
    }*/
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    mat-checkbox {
        .mdc-checkbox {
            border-color: var(--gris) !important;
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-ripple {
                border-color: var(--gris) !important;
                border-radius: 100%;
                border-width: calc(var(--font-size) * 0.1);
                top: calc((var(--mdc-checkbox-state-layer-size) - 60px) / 2) !important;
                left: calc((var(--mdc-checkbox-state-layer-size) - 60px) / 2) !important;
                width: 180% !important;
                height: 180% !important;
            }

            .mdc-checkbox__ripple {
                width: 90% !important;
                height: 90% !important;
                background-color: transparent !important;
            }
            
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mdc-checkbox__background {
                border-color: var(--colorText) !important;
                border-radius: 100%;
                width: 90% !important;
                height: 90% !important;
                margin: auto;
                border: 0.05em solid;
                top: calc((var(--mdc-checkbox-state-layer-size) - 40px) / 2) !important;
                left: calc((var(--mdc-checkbox-state-layer-size) - 40px) / 2) !important;

                * {
                    display: none;
                }
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*.mat-mdc-checkbox-touch-target {
                font-size: var(--font-size);
                width: 90% !important;
                height: 90% !important;
                margin-right: 0.8rem;
            }*/
        }

        .mdc-checkbox:hover {
            .mdc-checkbox__ripple {
                border-radius: 100% !important;
                border-width: calc(var(--font-size) * 0.1) !important;
                top: calc((var(--mdc-checkbox-state-layer-size) - 60px) / 2) !important;
                left: calc((var(--mdc-checkbox-state-layer-size) - 60px) / 2) !important;
                width: 180% !important;
                height: 180% !important;
            }
        }

        .mdc-label {
            color: var(--colorText) !important;
            font-family: 'Ubuntu', Muli, Helvetica Neue, Arial, sans-serif !important;            
            font-size: var(--font-size) !important;
            margin: 0 !important;
            margin-top: -3px !important;
            padding-left: 10px !important;
        }
    }

    .walinwa-app-contents {
        width: 100%;
        height: 100%;
    }

    .walinwa-main {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: var(--blanco);

        .walinwa-container {
            // width: 120vh;
            width: 100%;
            // min-width: 60vw;
            min-width: inherit;
            // max-width: 90vw;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            // padding: 1em 0;

            .center-center {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .buttons-xs {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 100%;

                button {
                    margin: 1em auto;
                    display: block;
                    // font-size: calc(var(--font-size) * 1.3) !important;
                }

                .button-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 1em;
                    width: 90% !important;
                    border-radius: 10em !important;

                    .logo-image {
                        width: 6rem;
                        height: 6rem;
                        border-radius: 100%;
                        margin-right: 1rem;
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                    .mat-button-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: var(--font-size);
                        min-height: 6em !important;

                        mat-form-field {
                            font-size: var(--font-size);
                            height: 6em !important;
                            min-height: 6em !important;
                            max-height: 6em !important;
                        }

                        .custom-form-field.inline {
                            display: flex;
                            height: 1em !important;
                            min-height: 1em !important;
                            max-height: 1em !important;

                            label {
                                white-space: nowrap;
                                margin-right: 1em;
                                height: 1em !important;
                            }

                            input {
                                height: 1em !important;
                            }
                        }

                        .custom-form-field {
                            font-size: var(--font-size);
                            height: 6em !important;
                            min-height: 6em !important;
                            max-height: 6em !important;
                            position: relative;

                            mat-datepicker-toggle {
                                position: absolute;
                                right: 0;
                                top: 10%;

                                svg {
                                    font-size: var(--font-size);
                                    width: 2em;
                                }
                            }

                            mat-icon {
                                position: absolute;
                                right: 0;

                                svg {
                                    font-size: var(--font-size);
                                    width: 2em;
                                }
                            }
                            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            mat-checkbox label {
                                color: var(--gris) !important;
                                font-style: normal;
                                font-weight: bold;
                                margin-left: 0;

                                sub {
                                    font-style: italic;
                                    font-weight: 100;
                                    margin-left: 0.5em;
                                    font-size: 1em;
                                }
                            }
                        }
                    }

                    .walinwa-row-space {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        .walinwa-table-container {
                            position: absolute;
                        }
                    }

                    .walinwa-row:not(.just-one-element)>* {
                        width: 45%;
                    }

                    .just-one-element>* {
                        width: 100% !important;
                    }

                    .walinwa-scroll-container {
                        width: 100%;
                        flex: 1;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;

                        img {
                            width: calc(var(--font-size) * 6);
                            border-radius: 100%;
                        }

                        span {
                            // font-size: calc(var(--font-size) * 2);
                        }
                    }
                }
            }
        }
    }

    .walinwa-main-blue-background {
        background-color: var(--azulFondo);
    }

    .walinwa-sidebar {
        background-color: var(--azul);
        width: calc(var(--font-size) * 13.5);
        min-height: calc(var(--font-size) * 41.88);
        margin-right: calc(var(--font-size) * 2.5);
        font-size: var(--font-size);
        padding: 2.75em 1.25em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .walinwa-sidebar-button {
            color: var(--blanco);
            // font-size: calc(var(--font-size) * 1.25);
            text-align: center;
            padding: 0.8em;
            border-radius: 2em;
            cursor: pointer;
            width: 100%;
            background: transparent;
            border: 0;
            font-family: "Ubuntu";
        }

        .walinwa-sidebar-button.active {
            background-color: var(--blanco);
            color: var(--gris);
        }

        .walinwa-sidebar-button:hover {
            background-color: var(--gris);
            color: var(--blanco);
        }
    }

    .walinwa-sidebar-verdeAzulado {
        background-color: var(--verdeAzulado);
    }

    .walinwa-box-login {
        .walinwa-box-header {
            padding: 0.5em 0.5em 0.5em 0.5em !important;
        }

        .walinwa-box-content {
            padding: 1.5em 1.5em 1.5em 1.5em !important;
        }
    }

    .walinwa-box {
        display: flex;
        flex-direction: column;
        width: 100%;
                        
        input[type="time"] {
            padding-bottom: 4px !important;
            margin-top: 0px !important;
        }
        
        .mat-mdc-text-field-wrapper {
            background-color: transparent !important;
            padding: 0px !important;

            .mat-datepicker-toggle {
                button {
                    padding-right: 0px !important;
                    padding-bottom: 0px !important;
                    padding-top: 20px !important;
                }
            }

            .mat-mdc-form-field-infix {
                @media only screen and (min-width: 1180px) {
                    border-bottom: 2px solid !important;
                }
                @media only screen and (max-width: 1180px) {
                    border-bottom: 2px solid !important;
                    input.mat-mdc-input-element[type="text"],
                    input.mat-mdc-input-element[type="password"] {
                    padding-bottom: 2px !important;
                    height: 24px !important;
                    margin-top: 8px !important;
                    }
                    .mat-mdc-select-value {
                        margin-top: 10px !important;
                    }
                }

                @media only screen and (max-width: 600px) {
                    border-bottom: 1px solid !important;
                    input.mat-mdc-input-element[type="text"],
                    input.mat-mdc-input-element[type="password"] {
                    padding-bottom: 2px !important;
                    height: 24px !important;
                    margin-top: none !important;
                }
                .mat-mdc-select-value {
                    margin-top: none !important;
                }
            }
                color: rgba(0, 0, 0, 0.42);
                padding: 2px 0px 2px 0px !important;

                input.mat-mdc-input-element[type="text"],
                input.mat-mdc-input-element[type="password"] {
                    padding-bottom: 2px !important;
                    height: 24px !important;
                }

                input.mat-mdc-input-element[type="number"] {
                    padding-top: 2px !important;
                    padding-bottom: 2px !important;
                }

                .mat-mdc-select {                        
                    padding-bottom: 0px !important;
                    padding-top: 0px !important;
                    padding-left: 0px !important;
                    margin-top: 0px !important;
                }
            }
        }
        
        // min-height: calc(var(--font-size) * 41.88);

        // @media (max-width: calc(#{$breakpoint-fuse-md})) {
        //     min-height: unset !important;
        // }

        .walinwa-box-header {
            // font-size: calc(var(--font-size) * 2);
            // padding: 0.25em calc(var(--font-size) * 3.25);
            // padding: 10px 25px;
            padding: 0.45rem 1.5625rem;
            font-weight: bolder;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-top: -0.01em;
            width: 100%;
            // margin-left: -0.1%;
            font-size: 120%;
        }

        form:not(.walinwa-box-content) {
            padding: 0 !important;
        }

        .walinwa-box-content-with-menu {
            display: flex;
            justify-content: center;
            width: 100%;
            flex: 1;

            .walinwa-box-menu {
                width: 14em;
                background: var(--verdeAzulado);
                border: 0.1em solid var(--verdeAzulado);
                color: var(--blanco);
                position: relative;
                font-size: var(--font-size);

                .walinwa-box-menu-item {
                    display: flex;
                    margin: 0.9em 0;
                    align-items: center;
                    padding: 0 1em;
                    // font-size: calc(var(--font-size) * 1.25);
                    cursor: pointer;

                    img {
                        width: 2.2em;
                        height: 2.2em;
                        margin-right: 1em;
                    }
                }

                .walinwa-box-menu-button {
                    position: absolute;
                    bottom: 5%;
                    border-radius: 2em;
                    padding: 0.3em 1em;
                    height: auto !important;
                    right: 10%;
                    // font-size: calc(var(--font-size) * 1.25) !important;
                    width: 80% !important;
                    font-weight: lighter;
                    line-height: 1.5em;
                    text-align: center;
                    color: var(--gris);
                    border: 0 !important;
                }
            }
        }

        .walinwa-box-content.walinwa-box-content-challenge {

            .walinwa-challenge-pending-title {
                padding: 0.5em;
                text-align: center;
                color: var(--blanco);
                background: var(--amarillo);
                // font-size: 1.3em;
                font-size: 120%;
                text-transform: uppercase;
                font-weight: bold;
                // margin-bottom: 1em;
                // font-size: calc(var(--font-size) * 1.5);
            }

            // .walinwa-challenge-buttons {
            //     // font-size: var(--font-size);
            //     padding: 2em 4em;
            //     display: flex;
            //     justify-content: space-between;
            // }
        }

        .walinwa-box-content-messages {
            // background-color: var(--blanco);
            position: relative;

            .walinwa-contact-message {
                display: flex;
                flex-direction: column;
                // background: var(--blanco);
                // padding: 2%;
                border: 0.05em solid var(--azul);
                // color: var(--gris);
                // margin-bottom: 1em;
                // font-size: var(--font-size);
                padding: 0.5rem;

                .message-date {
                    text-align: right;
                    // font-size: calc(var(--font-size) * 0.85);
                }

                .message {
                    // font-size: calc(var(--font-size) * 1.2);
                    word-break: break-word;
                }
            }

            .walinwa-contact-message {
                .subject {
                    font-weight: bold;
                }
            }

            .walinwa-contact-message.izquierda {
                width: 90%;
                // float: left;
            }

            .walinwa-contact-message.derecha {
                width: 90%;
                // float: right;
                background: #e3fcff;
                align-self: end;
            }

            .walinwa-contact-message.visto {
                border: 0.1em solid var(--verde);
            }
        }

        .walinwa-box-content {
            // background-color: var(--blanco);
            // background-color: #e7ebec;
            background-color: #F1F4F5;
            flex: initial;
            display: flex;
            flex-direction: column;
            // padding: calc(var(--font-size) * 1.75) calc(var(--font-size) * 3);
            // padding: 25px;
            padding: 1.5625rem;
            position: relative;
            align-items: stretch;
            // row-gap: 20px;
            // column-gap: 20px;
            row-gap: 1rem; //TODOJO 1.25
            column-gap: 1.25rem;
            justify-content: space-between;

            .final-import {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // font-size: calc(var(--font-size) * 1.2);
                padding: 0.5em;
                border: 0.05em solid var(--gris);
                color: var(--gris);
                width: 50%;
                margin: 2em auto;
            }

            .walinwa-box-content-loading {
                font-size: var(--font-size);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(255, 255, 255, 0.5);
                z-index: 1;

                img {
                    font-size: var(--font-size);
                    width: 10em;
                }
            }

            table {
                // font-size: calc(var(--font-size) * 1.2);
                // color: var(--gris);

                th {
                    border: 0;
                    background: var(--azul);
                    color: var(--blanco);
                    padding: 0.3em 1em;
                    white-space: nowrap;
                    font-weight: bolder;
                }

                td,
                tr {
                    // border-bottom: 0.05em solid var(--gris);
                    cursor: pointer;
                }

                tr {
                    border-bottom: 0.05em solid var(--gris);
                    // border-left: 0.05em solid var(--gris);
                    // border-right: 0.05em solid var(--gris);
                }

                .selected {
                    background: var(--azulFondo);
                }
            }

            .walinwa-box-icon {
                width: calc(var(--font-size) * 10);
                height: calc(var(--font-size) * 10);
                border-radius: 100%;
                margin-bottom: 2em;
            }

            .legend {
                color: var(--gris);
                text-align: justify;
                // font-size: calc(var(--font-size) * 0.99);
            }

            h1 {
                color: var(--gris);
                text-align: center;
                // font-size: calc(var(--font-size) * 2);
            }

            h2 {
                // font-size: calc(var(--font-size) * 1.25);
                color: var(--gris);
                margin-top: 0;
            }

            .walinwa-row.sm-walinwa-row {
                // min-height: auto !important;
                // max-height: 3em !important;

                .custom-form-field {
                    height: auto !important;
                    min-height: auto !important;
                    max-height: auto !important;
                }

                // label{
                //     font-size: var(--font-size);
                //     margin-bottom: 0.5em;
                // }

                select {
                    font-size: var(--font-size);
                    margin-bottom: 0.3em;
                }
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                mat-checkbox {
                    font-size: var(--font-size);
                    // margin-bottom: 1em;
                    margin-bottom: 20px;
                }

                sub {
                    bottom: 0;
                    // font-size: calc(var(--font-size) * 1.25);
                    margin-left: 0.5em;
                }
            }

            .calendars-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-size: var(--font-size);
                min-height: 5em !important;
                flex-direction: row;

                .calendar-row {
                    width: 45%;

                    .calendar-row-label {
                        font-weight: bolder;
                        color: var(--azul);
                        margin-bottom: 1em;
                        // font-size: calc(var(--font-size) * 1.25);
                    }

                    select.calendar-row-label {
                        width: 100%;
                        border: 0;
                        margin: 0;
                        border-bottom: 0.1em solid var(--azul);
                    }
                }
            }

            .walinwa-row.sm {
                min-height: 3em !important;
            }

            .walinwa-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-size: var(--font-size);
                // min-height: 5em !important;
                flex-direction: row;

                h2 {
                    margin: 0;
                }

                mat-form-field {
                    // font-size: calc(var(--font-size) * 0.8);
                    height: 7em !important;
                    min-height: 7em !important;
                    max-height: 7em !important;

                    .legend {
                        margin-top: 3.2em;
                        margin-bottom: -25%;
                    }
                }
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                mat-checkbox.margin-botom {
                    margin-bottom: 1em;
                }

                mat-form-field.margin-botom {
                    margin-bottom: 2em;
                }

                mat-form-field.disabled * {
                    color: var(--gris) !important;
                }

                .custom-form-field.margin-botom {
                    margin-bottom: 2em;
                }

                .custom-form-field.disabled * {
                    color: var(--gris) !important;
                }

                .custom-form-field.inline {
                    display: flex;
                    align-items: center;
                    height: 3em !important;
                    min-height: 3em !important;

                    label {
                        min-width: 15em;
                        width: 15em;
                    }
                }

                .custom-form-field {
                    // font-size: calc(var(--font-size) * 0.8);
                    height: 7em !important;
                    min-height: 7em !important;
                    max-height: 7em !important;
                    position: relative;

                    label {
                        sub {
                            bottom: 0;
                            font-weight: 100;
                        }
                    }

                    mat-datepicker-toggle {
                        position: absolute;
                        right: 0;
                        top: 10%;

                        svg {
                            font-size: var(--font-size);
                            width: 2em;
                        }
                    }

                    mat-icon {
                        position: absolute;
                        right: 0;

                        svg {
                            font-size: var(--font-size);
                            width: 2em;
                        }
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    mat-checkbox label {
                        color: var(--gris) !important;
                        font-style: normal;
                        font-weight: bold;
                        margin-left: 0;

                        sub {
                            font-style: italic;
                            font-weight: 100;
                            margin-left: 0.5em;
                            font-size: 1em;
                        }
                    }
                }

                .leyend {
                    // font-size: calc(var(--font-size) * 0.9);
                    font-style: italic;
                    color: var(--gris);
                    text-align: left;
                }

                .license {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .price {
                        color: var(--blanco);
                        // font-size: calc(var(--font-size) * 5);
                        margin: 0.75em 0 0.25em 0;
                    }

                    .desc {
                        color: var(--blanco);
                        // font-size: calc(var(--font-size) * 1.5);
                        text-align: center;
                        width: 50%;
                        line-height: 1em;
                        margin: 1em 0 4em 0;
                    }
                }

                .license:nth-of-type(1) {
                    background: var(--naranja);
                }

                .license:nth-of-type(2) {
                    background: var(--verde);
                }

                .license:nth-of-type(3) {
                    background: var(--verdeAzulado);
                }

                .custom-form-field.bordered-custom-form-field {
                    height: 14.59em !important;
                    min-height: auto !important;
                    max-height: unset !important;
                    border: 1px solid var(--gris);
                    overflow-y: auto;
                    padding: 0.5em;
                    font-size: var(--font-size);
                    margin-bottom: 0;
                    display: grid;

                    label {
                        font-size: var(--font-size);
                        margin-bottom: 0.5em;
                    }
                }

                .custom-form-field.bordered2-custom-form-field {
                    height: 14em !important;
                    min-height: auto !important;
                    max-height: unset !important;
                    border: 1px solid var(--azul);
                    overflow-y: auto;
                    padding: 0.5em;
                    font-size: var(--font-size);
                    display: grid;
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    /*.mat-mdc-checkbox-label {
                        font-weight: lighter !important;
                        white-space: nowrap;
                    }*/
                    .mat-mdc-checkbox {
                        .mdc-label {
                            font-weight: lighter !important;
                            white-space: nowrap;   
                        }
                    }

                    label {
                        font-size: var(--font-size);
                        margin-bottom: 0.5em;
                    }
                }
            }

            .walinwa-row-space {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;

                .walinwa-table-container {
                    position: absolute;
                }
            }

            // .walinwa-row:not(.just-one-element)>* {
            //     width: 45%;
            // }

            .just-one-element>* {
                width: 100% !important;
            }

            .three-elements>* {
                width: 30% !important;
            }

            .walinwa-card {
                font-size: var(--font-size);
                // height: 30em;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                margin: 1em 0;

                .walinwa-card-image {
                    width: 45%;
                    margin: 10% 0;
                }

                .walinwa-card-text {
                    // font-size: calc(var(--font-size) * 2);
                    color: var(--blanco);
                    text-align: center;
                    line-height: 1.2em;
                }

                .walinwa-card-button {
                    background-color: var(--blanco);
                    color: var(--gris);
                    margin: 1em 0;
                    font-weight: lighter;
                    width: 90% !important;
                }
            }

            .walinwa-card-blue {
                background-color: var(--azul);
            }

            .walinwa-card-orange {
                background-color: var(--naranja);
            }

            .walinwa-card-green-blue {
                background-color: var(--verdeAzulado);
            }

            .walinwa-scroll-container {
                width: 100%;
                flex: 1;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
            }

            .walinwa-table-container.challenge-table-container {
                width: 100%;
                height: 13em;
                overflow: auto;

                .walinwa-table.challenge-table {
                    width: 100%;
                }
            }

            .walinwa-table-container {
                width: 100%;
                height: 100%;
                overflow-y: auto;
                // border: 0.05em solid var(--azul);

                .walinwa-table {
                    width: 100%;
                    border-collapse: collapse;
                    // font-size: calc(var(--font-size) * 1.2);

                    th:nth-of-type(1),
                    td:nth-of-type(1) {
                        padding-left: 3%;
                    }

                    i {
                        color: var(--blanco) !important;
                    }

                    th {
                        border: 0;
                        background: var(--azul);
                        color: var(--blanco) !important;
                        padding: 0.3em 1em;
                        white-space: nowrap;
                        font-weight: bolder;
                        text-align: left;
                    }

                    .walinwa-table-header-verdeAzulado {
                        th {
                            background: var(--verdeAzulado);
                        }
                    }

                    td {
                        border: 0;
                        color: var(--gris);
                        padding: 0.1em 1em;
                        white-space: nowrap;
                        background-color: var(--blanco);
                    }

                    .walinwa-table-row.ko {
                        background: var(--naranjaTablas);
                    }

                    .walinwa-table-row.ok {
                        background: var(--azulFondo);
                    }

                    .walinwa-table-row {
                        border-bottom: 0.05em solid var(--gris);
                        cursor: pointer;
                    }

                    .walinwa-table-row.disabled td {
                        color: #ff0000;
                    }

                    .walinwa-table-row.selected td {
                        background: var(--azulFondo);
                    }
                }

                .walinwa-parent-students-table {
                    th {
                        border: 0;
                        background: var(--verde);
                        color: var(--blanco) !important;
                        padding: 1.2em 1em;
                        white-space: nowrap;
                        font-weight: bolder;
                        text-align: left;
                    }
                }
            }

            .walinwa-table-container-no-border {
                border: 0;
            }

            mat-form-field label {
                // font-size: calc(var(--font-size) * 1.75) !important;
            }

            mat-form-field input {
                // font-size: calc(var(--font-size) * 1.25) !important;
                //margin-top: calc(var(--font-size) * 0.75); <--- quitado TODOJO
            }

            mat-form-field input[disabled] {
                background-color: rgba(0, 0, 0, 0.05);
            }

            .inline-custom-form-field {
                font-size: var(--font-size);
                display: flex;
                align-items: flex-end;

                label {
                    color: var(--gris);
                    // font-size: calc(var(--font-size) * 1.25) !important;
                    display: block;
                    white-space: nowrap;
                    margin-right: 0.5em;
                }

                input,
                select,
                textarea {
                    background-color: transparent;
                    border: 0;
                    // font-size: calc(var(--font-size) * 1.25) !important;
                    margin-top: calc(var(--font-size) * 0.5);
                    border-bottom: 0.1em solid rgba(0, 0, 0, 0.42);
                    color: var(--gris);
                    width: 100%;
                    resize: none;
                    padding: 0;
                }
            }

            .custom-form-field {
                font-size: var(--font-size);

                .legend {
                    margin-top: 2rem;
                }

                label {
                    font-weight: bold;
                    color: var(--azul);
                    // font-size: calc(var(--font-size) * 1.25) !important;
                    display: block;
                }

                input,
                select,
                textarea {
                    background-color: transparent;
                    border: 0;
                    // font-size: calc(var(--font-size) * 1.25);
                    margin-top: calc(var(--font-size) * 0.5);
                    border-bottom: 0.15em solid rgba(0, 0, 0, 0.42);
                    color: var(--gris);
                    width: 100%;
                    resize: none;
                    padding: 0;
                    font-family: Ubuntu;
                }
            }

            // button {
            //     // font-size: calc(var(--font-size) * 1.2);
            //     height: 2em !important;
            // }

            .walinwa-box-exercise-cs {
                width: 100% !important;
                height: 100% !important;
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center !important;
                flex-direction: column !important;
            }

            .walinwa-box-exercise {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                // padding: 2em;
                .center {
                    .exerciseTitle {
                        font-size: var(--font-size);
                        height: 2.5em;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 2em;

                        p {
                            width: 100%;
                            background-color: rgba(0, 0, 0, 0.3);
                            padding: 1%;
                            border-radius: 0;
                            color: var(--blanco);
                            line-height: 2vh;
                            // font-size: calc(var(--font-size) * 1.5);
                        }

                        span {
                            width: 100%;
                            // font-size: calc(var(--font-size) * 1.5);
                            color: var(--blanco);
                        }
                    }

                    @media (max-width: 1440px) {
                        .exerciseTitle-slide {
                            p {
                                // font-size: calc(var(--font-size) * 1) !important;
                            }

                            span {
                                // font-size: calc(var(--font-size) * 1) !important;
                            }
                        }
                    }
                }

                .exercise-title {
                    font-size: var(--font-size);
                    background: rgba(0, 0, 0, 0.3);
                    height: 2.5em;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 2em;

                    span {
                        width: 100%;
                        // font-size: calc(var(--font-size) * 1.5);
                        color: var(--blanco);
                    }
                }

                .words-container {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    width: 90%;
                    // font-size: calc(var(--font-size) * 2);
                    margin: 1.5em;

                    .word-box {
                        overflow: hidden;
                        display: flex;
                        margin: 2.5%;
                        padding: 2.5%;
                        border-radius: 0;
                        background: var(--blanco);
                        color: var(--gris);
                        box-shadow: -0.2em 0.4em 1em rgb(0 0 0);
                        // font-size: calc(var(--font-size) * 2);
                    }

                    .phrase-box {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        // font-size: calc(var(--font-size) * 1.5);
                        color: var(--azulTexto);
                        width: 100%;
                        justify-content: center;
                        background-color: var(--blanco);
                        padding: 1em;
                        margin: 0.4em;

                        .phrase {
                            font-family: Arial, Helvetica, sans-serif;
                            text-align: justify;
                            font-style: italic;
                            border-radius: 0;
                            background: var(--blanco);

                            .subPhraseEdge {
                                padding: 0 0.2em;
                            }
                        }

                        .author-box {
                            // font-size: calc(var(--font-size) * 0.75);
                            overflow: hidden;
                            font-style: italic;
                            text-align: right;
                            width: 100%;

                            span {
                                font-style: normal;
                            }
                        }
                    }
                }

                .options-container {
                    text-align: center;
                    max-height: 40%;
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    // font-size: calc(var(--font-size) * 2);

                    .option {
                        margin: 3.5%;
                        background: var(--azul);
                        color: var(--blanco);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: -0.5em 0.4em 1em rgba(0, 0, 0, 0.8);
                        padding: 0.25em 1.5em;
                        border-radius: 1em;
                        // font-size: calc(var(--font-size) * 2);

                        span {
                            // font-size: calc(var(--font-size) * 1.5);
                        }
                    }
                }
            }
        }

        .walinwa-box-content.no-padding {
            padding: 0 !important;
        }
    }

    .walinwa-box-with-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(var(--font-size) * 42);

        .walinwa-box {
            height: 100%;
        }

        .walinwa-box-menu {
            width: 16.3em;
            height: 100%;
            background: var(--verdeAzulado);
            border: 0.1em solid var(--verdeAzulado);
            color: var(--blanco);
            position: relative;
            font-size: var(--font-size);

            .walinwa-box-menu-item {
                display: flex;
                margin: 1em 0 1em 0em;
                align-items: center;
                padding: 0.25em 1em;
                // font-size: calc(var(--font-size) * 1.25);
                cursor: pointer;

                img {
                    width: 2.2em;
                    height: 2.2em;
                    margin-right: 1em;
                }
            }

            .walinwa-box-menu-button {
                position: absolute;
                bottom: 5%;
                border-radius: 2em;
                padding: 0.3em 1em;
                height: auto !important;
                right: 10%;
                // font-size: calc(var(--font-size) * 1.25) !important;
                width: 80% !important;
                font-weight: lighter;
                line-height: 1.5em;
                text-align: center;
                color: var(--gris);
            }
        }
    }

    .walinwa-box-with-tabs {
        position: relative;
        
        input[type="time"] {
            padding-bottom: 4px !important;
            margin-top: 0px !important;
        }

        .mat-mdc-text-field-wrapper {
            background-color: transparent !important;
            padding: 0px !important;

            .mat-mdc-form-field-infix {
                @media only screen and (min-width: 1024px) {
                    border-bottom: 2px solid !important;
                }
                
                @media only screen and (max-width: 1023px) {
                    border-bottom: 1px solid !important;
                }

                color: rgba(0, 0, 0, 0.42);
                padding: 2px 0px 2px 0px !important;

                input.mat-mdc-input-element[type="text"] {
                    padding-bottom: 2px !important;
                    height: 24px !important;
                }

                .mat-mdc-select {                        
                    padding-bottom: 0px !important;
                    padding-left: 0px !important;
                    margin-top: 0px !important;
                    padding-top: 0px !important;
                }
            }
        }

        .walinwa-tabs {
            display: flex;
            flex-wrap: wrap-reverse;
            margin: 1em 5%;
        }

        .walinwa-tabs.walinwa-tabs-top {
            // position: absolute;
            margin: 0;
            border-bottom: 3px solid var(--blanco);

            // top: -1.8em !important;
            // height: 1.8em !important;
            // height: 30px;
            // height: 1.875rem;
            // font-size: calc(var(--font-size) * 1.2);
            .walinwa-tab-gray {
                background: var(--grisPestanas);
                color: var(--blanco);
                padding: 0.2em 0.8em;
                // margin-right: 0.3em;
                margin-right: 0.1875rem;
                cursor: pointer;
            }

            .walinwa-tab-gray.selected {
                background: var(--verde);
                color: var(--blanco);
            }

            .walinwa-tab-white {
                background: var(--blanco);
                color: var(--gris);
                padding: 0.2em 0.8em;
                margin-right: 0.3em;
                cursor: pointer;
            }

            .walinwa-tab-white.orange-selected {
                background: var(--naranja);
                color: var(--blanco);
            }

            .walinwa-tab-gray.orange-selected {
                background: var(--naranja);
                color: var(--blanco);
            }

            .walinwa-tab-orange {
                background: var(--blanco);
                color: var(--gris);
                padding: 0.2em 0.8em;
                margin-right: 0.3em;
            }

            .walinwa-tab-orange.selected {
                background: var(--naranja);
                color: var(--blanco);
                border: 0.05em solid var(--naranja);
            }
        }
    }

    .walinwa-box-green,
    .walinwa-box-blue {
        // border: 0.1em solid var(--verde);
        // border-top: 3px solid var(--blanco);
        border: 0;

        .walinwa-box-header {
            background-color: var(--verde);
            color: var(--blanco);
            font-size: 120%;

            sub {
                margin-left: 1em;
                // font-size: calc(var(--font-size) * 1.1);
            }

            .walinwa-header-button {
                // font-size: calc(var(--font-size) * 1.25);
                font-weight: lighter;
                display: flex;
                align-items: center;
                margin-left: 1.5em;
                cursor: pointer;

                img {
                    height: calc(var(--font-size) * 2.5);
                    font-size: var(--font-size);
                    margin-right: 0.5em;
                }

                select {
                    padding-right: 1em;
                    font-size: var(--font-size);
                    border-color: var(--blanco);
                    font-weight: lighter;
                }
            }

            select {
                background-color: transparent;
                color: var(--blanco);
                font-weight: bold;
                border: 0.1em solid var(--blanco);
                // font-size: calc(var(--font-size) * 1.3);
                padding: 0.5rem 0.8rem;
                background-image: url("https://walinwa.blob.core.windows.net/images/blanco-96-2.png");
                background-size: auto 70%;
                background-repeat: no-repeat;
                background-position: 98% center;
                appearance: none;
                border-radius: 8px;

                option {
                    color: var(--gris);
                }
            }

            .walinwa-box-header-close-icon {
                font-size: var(--font-size);
                height: 4em;
                filter: invert(100%) brightness(2);
                padding: 10px;
            }
        }

        .walinwa-box-icon {
            background-color: var(--verde);
        }
    }

    .walinwa-box-yellow {
        // border: 0.1em solid var(--amarillo);
        background-color: #F1F4F5;

        .walinwa-box-header {
            background-color: var(--amarillo);
            color: var(--blanco);
        }

        .walinwa-box-icon {
            background-color: var(--amarillo);
        }
    }

    .walinwa-box-orange {
        // border: 0.1em solid var(--naranja);
        background-color: #F1F4F5;

        .walinwa-box-header {
            background-color: var(--naranja);
            color: var(--blanco);
        }

        .walinwa-box-icon {
            background-color: var(--naranja);
        }
    }

    .walinwa-box-purple {
        // border: 0.1em solid var(--morado);
        background-color: #F1F4F5;

        .walinwa-box-header {
            background-color: var(--morado);
            color: var(--blanco);
        }

        .walinwa-box-icon {
            background-color: var(--morado);
        }
    }

    .walinwa-box-verdeAzulado {
        // border: 0.1em solid var(--verdeAzulado);
        background-color: #F1F4F5;

        .walinwa-box-header {
            background-color: var(--verdeAzulado);
            color: var(--blanco);
        }

        .walinwa-box-icon {
            background-color: var(--verdeAzulado);
        }
    }

    .walinwa-box-moradoClaro {
        // border: 0.1em solid var(--moradoClaro);
        background-color: #F1F4F5;

        .walinwa-box-header {
            background-color: var(--moradoClaro);
            color: var(--blanco);
        }

        .walinwa-box-icon {
            background-color: var(--moradoClaro);
        }
    }

    .walinwa-box-blue {
        // border: 0.1em solid var(--azul);
        border: 0;
        // border-radius: 20px;

        .walinwa-box-header {
            background-color: var(--azul);
            color: var(--blanco);
            border-radius: 20px 20px 0 0;
        }

        .walinwa-box-icon {
            background-color: var(--azul);
        }

        .walinwa-box-content {
            border-radius: 0 0 20px 20px;
        }
    }

    .walinwa-box-green {

        // 
        .walinwa-box-header {
            border-radius: 20px 20px 0 0;
        }

        .walinwa-box-content {
            border-radius: 0 0 20px 20px;
        }
    }

    .walinwa-box-daily-text {
        display: block;

        .ecoText {
            border: 0.1em solid var(--verde);
        }

        .weeklyTitle {
            // font-size: var(--font-size);
        }

        .dailyText {
            // margin: 0 5%;
            // text-align: justify;
            // padding-top: 3%;
            // font-style: italic;
            // max-height: 70%;
            // max-width: 90%;
            // min-height: 7em;
            // font-size: var(--font-size);
            // display: inline-block;

            .dailyTextSpan {
                // text-align: justify;
                // display: block;
                // margin-bottom: 0.5em;
                // color: var(--gris);
                // font-size: calc(var(--font-size) * 1.2);
            }

            .sundayStar {
                // width: 4em;
                // position: absolute;
            }
        }

        .sundayText .dailyTextSpan {
            // margin-left: 6em;
            font-style: normal;
        }

        .dailyText.bg {
            // padding-top: 1%;
        }

        .dailyText.med {
            // padding-top: 2%;
        }

        .dailyText.sm {
            // padding-top: 3%;
        }

        .dailyTextStars {
            margin: 0;
            // max-width: 65% !important;
            position: relative;

            .dailyTextSpan {
                color: var(--gris) !important;
            }

            .trofeo {
                // height: 10em;
                // position: absolute;
                // top: 0;
                // right: -10.5em;
            }
        }

        .stars {
            // width: 12%;
            // float: left;
            // margin: 2%;
        }

        .dailyTextSpan {
            // text-align: justify;
            // display: block;
            // margin-bottom: 0.5em;
            // color: var(--gris);
            // font-size: calc(var(--font-size) * 1.2);
        }

        .dailyTextDidYouKnow {
            // text-align: justify;
            // font-style: italic;
            // height: 50%;
            // width: 99%;
            // display: table;
            // padding: 0 5%;
        }

        .dailyTextDidYouKnow.sm {
            // padding-top: 3%;
        }

        .dailyTextDidYouKnow.bg {
            // padding-top: 0.5%;
        }

        .dailyTextDidYouKnow.med {
            // padding-top: 2%;
        }

        .dailyTextEcoembes {
            // text-align: justify;
            // /*font-style: italic;*/
            // height: 56%;
            // width: 99%;
            // display: table;
            // padding: 1% 5%;
            // position: relative;

            .dailyTextSpan {
                // margin-left: 14%;
            }

            .sundayStar {
                // width: 4em;
                // position: absolute;
                // bottom: 1.5em;
            }
        }

        .weeklyText .dailyTextSpan {
            color: var(--negro);
        }

        .dailyTextSpan.pequeño {
            line-height: 1.1em;
            // font-size: calc(var(--font-size) * 0.9);
            padding-bottom: 1em;
        }

        .dailyAuthorContainer {
            // width: 100%;
            // height: 20%;
            // display: flex;
            // justify-content: flex-end;
            // align-items: center;
            // padding: 0 2em;
        }

        .dailyAuthor {
            // overflow: hidden;
            // width: 100%;
            // font-style: normal;
            // text-align: right !important;
            // font-size: var(--font-size);
            // color: var(--gris);
        }

        .dailyButtons {
            // width: 100%;
            // display: flex;
            // justify-content: space-around;
            // align-items: center;
            // padding: 0.5em 0;

            img {
                // width: 22.5%;
            }
        }

        .sundayButtons {
            // display: flex;
            // justify-content: space-around;
            // align-items: center;
            // width: 100%;
            // height: 100%;
        }

        .sundayButtons .boton {
            position: static;
            min-height: 2rem;
        }

        .sundayButtons .new_but:nth-of-type(1),
        .sundayButtons .boton:nth-of-type(1) {
            background: var(--naranja);
            color: var(--blanco);
        }

        .sundayButtons .new_but:nth-of-type(2),
        .sundayButtons .boton:nth-of-type(2) {
            background: var(--verdeAzulado);
            color: var(--blanco);
        }

        .sundayButtons .new_but:nth-of-type(3),
        .sundayButtons .boton:nth-of-type(3) {
            background: var(--verde);
            color: var(--blanco);
        }

        .sundayButtons .new_but.ok,
        .sundayButtons .boton.ok {
            background: var(--fucsia);
            color: var(--blanco);
        }

        .sundayButtons .new_but.err,
        .sundayButtons .boton.err {
            background: #ccc;
            color: var(--blanco);
        }

        .dailyBook {
            font-style: italic;
            display: inline-block;
            // font-size: var(--font-size);
        }

        .buttonDisabled {
            opacity: 0.2 !important;
            -khtml-opacity: 0.2 !important;
            -moz-opacity: 0.2 !important;
        }

        .dailyButtonImg {
            width: 20%;
            margin: 3%;
        }

        .like:not(.frase) {
            top: 0;
            left: 0%;
            width: 17%;
            height: 60%;
        }

        .dontLike:not(.frase) {
            top: 0;
            left: 22%;
            width: 17%;
            height: 60%;
        }

        .dontKnow:not(.frase) {
            top: 0;
            left: 44%;
            width: 20%;
            height: 60%;
        }

        .likeButton:not(.frase) {
            box-shadow: 0 0 0 1px #8fa9b9;
            border-radius: 3px;
            position: absolute;
            width: 21%;
            display: flex !important;
            align-items: center !important;
            justify-content: flex-start !important;

            img {
                width: 2.5vh;
                height: 2.5vh;
                margin-left: 1vh;
            }
        }

        .likeButton:not(.frase)>* {
            margin: 0.5vh;
        }

        .like.frase {
            height: 120%;
            margin-right: 1em;
        }

        .dontLike.frase {
            height: 120%;
        }

        .dontKnow.frase {
            margin-right: 1em;
            height: 120%;
        }

        .likeButton.frase {
            height: 120%;
            margin-right: 1em;
        }

        .likeButton.frase>* {
            margin: 0.5vh;
        }

        .likeButton.frase img {
            width: 2.5vh;
            height: 2.5vh;
            margin-left: 1vh;
        }
    }

    .two-boxes {
        .walinwa-box {
            width: calc(var(--font-size) * 31);
            margin: 0 1em;
        }
    }

    .walinwa-footer-colors {
        display: flex;
        height: 1.3em;
        font-size: var(--font-size);

        .walinwa-footer-color {
            width: 33.333%;
            height: 100%;
        }

        .walinwa-footer-color-1 {
            background: var(--naranja);
        }

        .walinwa-footer-color-2 {
            background: var(--verdeAzulado);
        }

        .walinwa-footer-color-3 {
            background: var(--verde);
        }
    }

    .walinwa-switch {
        display: flex;
        color: var(--blanco);
        align-items: center;
        // font-size: calc(var(--font-size) * 1.219);

        .walinwa-switch-options {
            margin: 0 1em;
            border-radius: 1em;
            border: 0.1em solid var(--blanco);
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 5em;
            height: 2em;

            .walinwa-switch-option {
                border-radius: 100%;
                border: 0.1em solid var(--blanco);
                width: 1em;
                height: 1em;
                margin: 0 0.4em;
            }

            .walinwa-switch-option.selected {
                background: var(--azul);
            }
        }
    }

    .flex-direction-column {
        flex-direction: column;
    }

    .walinwa-header {
        background: var(--azul);
        font-size: var(--font-size);

        .walinwa-header-container {
            width: 120vh;
            min-width: 60vw;
            max-width: 100%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.6em 0;

            .walinwa-header-logo {
                height: 7.3em;
            }

            .walinwa-header-buttons {
                @keyframes blink {
                    0% {
                        opacity: 1;
                    }

                    50% {
                        opacity: 0.25;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                .blink {
                    animation: blink 3s infinite;
                }

                .walinwa-header-button {
                    height: 6em;
                    border-radius: 100%;
                    margin-left: 1em;
                    cursor: pointer;
                }
            }

            .walinwa-header-user {
                display: flex;
                align-items: center;

                .walinwa-header-user-info {
                    font-size: var(--font-size);
                    height: 5em;
                    margin: 0 0.2em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // margin-left: 2em;
                    position: relative;

                    .username {
                        text-align: right;
                        color: var(--amarillo);
                        font-size: 1.5em;
                    }

                    .email {
                        color: var(--blanco);
                        text-align: right;
                        font-size: 1.25em;
                    }

                    .walinwa-header-user-info-primary {
                        text-align: right;
                        color: var(--amarillo);
                        font-size: 1.3em;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    .walinwa-header-user-info-secondary {
                        color: var(--blanco);
                        text-align: right;
                        font-size: 1.25em;
                    }
                }

                .walinwa-header-user-image {
                    min-width: 6.25em;
                    height: 6.25em;
                    background: var(--naranja);
                    border-radius: 100%;
                    margin-left: 1em;
                    width: 6.25em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.8em;
                }

                .walinwa-header-student-image {
                    height: 6.25em;
                    background-color: var(--blanco);
                    border-radius: 100%;
                    margin-left: 1em;
                    width: 6.25em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.8em;
                }
            }

            .treasure {
                font-size: var(--font-size);
                width: 11em;
                height: 8em;
                background-size: contain;
                background-repeat: no-repeat;
                cursor: pointer;
                // margin-right: 2em;
            }

            .coins-plank {
                width: 14em;
                height: 8em;
                font-size: var(--font-size);
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: center;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 1em;

                .coinsNumber {
                    font-weight: bold;
                    width: 90%;
                    float: right;
                    text-align: center;
                    // font-size: calc(var(--font-size) * 1.2);
                    color: wheat;
                    text-shadow: 0 0 14px var(--negro);
                    position: relative;
                }

                .coinsInfo {
                    position: absolute;
                    top: -21%;
                    right: -10%;
                    font-size: var(--font-size);
                    width: 2.3em;
                    cursor: pointer;
                }

                .coinsNumber.oscuro {
                    color: #7e7360 !important;
                    text-shadow: 0 0 14px var(--blanco);
                }

                .coinsNumber p {
                    padding: 0;
                    margin: -2%;
                    font-size: auto !important;
                }
            }
        }
    }

    .profile-picture {
        height: 6.25em;
        background: var(--naranja);
        border-radius: 100%;
        margin-left: 1em;
        width: 6.25em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8em;
        font-size: var(--font-size);
    }

    .walinwa-footer {
        background-color: var(--gris);
        font-size: var(--font-size);
        min-height: 3.5em;

        .walinwa-footer-container {
            width: 120vh;
            min-width: 60vw;
            max-width: 100%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.55em 0;
            font-size: var(--font-size);

            .submit-button {
                background-color: var(--blanco);
                color: var(--gris);
                // font-size: calc(var(--font-size) * 1.3) !important;
            }

            .submit-button:hover {
                color: var(--blanco) !important;
            }

            .go-back:hover {
                background-color: var(--naranja) !important;
                color: var(--blanco) !important;
            }

            .go-back {
                width: 7.5em !important;
                background-color: var(--naranja);
                color: var(--blanco) !important;
                display: flex;
                justify-content: center;
                align-items: center;
                // font-style: italic !important;
                /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                .mat-button-wrapper {
                    justify-content: center;
                }

                .secondary-text {
                    transform: rotate(-90deg);
                    position: absolute;
                    left: 0.5em;
                    color: var(--blanco) !important;
                }

                mat-icon {
                    margin-right: 1em;
                }
            }

            .exit {
                width: 12em !important;
                background-color: var(--blanco);
                color: var(--gris) !important;
                display: flex;
                justify-content: center;
                align-items: center;
                // font-style: italic !important;
                /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                .mat-button-wrapper {
                    justify-content: center;
                }

                .secondary-text {
                    transform: rotate(-90deg);
                    position: absolute;
                    left: 0.5em;
                    display: none;
                    color: var(--gris) !important;
                }

                mat-icon {
                    margin-right: 1em;
                }
            }
        }
    }

    .exerciseTheme {
        position: absolute;
        font-size: var(--font-size);
        bottom: -4em;
        left: -10.5em;
        color: var(--blanco);

        span {
            // font-size: calc(var(--font-size) * 1.5);
        }
    }

    .exerciseTheme h1 {
        font-size: 1.6vh !important;
    }

    // calendar
    .walCalendar {
        display: unset;
        // border: 0.1em solid var(--naranja);
    }

    .walCalendar>div.header {
        background: var(--naranja);
        color: var(--blanco);
        // float: left;
        width: 100%;
        font-size: var(--font-size);
        padding: 0.45rem  0.2em;
        display: flex;
        align-items: center;
    }

    .walCalendar>div.header>* {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .walCalendar>div.header>i {
        box-sizing: border-box;
        cursor: pointer;
        float: left;
        font-weight: bold;
        color: var(--blanco);
        position: relative;
        width: 10%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        // font-size: calc(var(--font-size) * 2);
    }

    .walCalendar>div.header>i.fa-angle-left {
        text-align: center;
    }

    .walCalendar>div.header>i.fa-angle-right {
        text-align: center;
    }

    .walCalendar>div.header>span {
        box-sizing: border-box;
        color: inherit;
        float: left;
        font-weight: bold;
        font-size: 1.3em;
        text-align: center;
        text-transform: lowercase;
        width: 80%;
        flex-direction: column;
        display: flex;
        display: -webkit-box;
        /*display: -moz-box;*/
        display: -ms-flexbox;
        display: -webkit-flex;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .calendar-session>.walCalendar>div.header>span {
        box-sizing: border-box;
        color: inherit;
        float: left;
        font-weight: bold;
        font-size: 1.5em;
        text-align: center;
        text-transform: uppercase;
        width: 80%;
        flex-direction: column;
        display: flex;
        display: -webkit-box;
        /*display: -moz-box;*/
        display: -ms-flexbox;
        display: -webkit-flex;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .walCalendar>div.week {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0.5em 0;
    }

    .walCalendar>div.week:first-child {
        border-top: medium none;
    }

    .walCalendar>div.week>span.day {
        // background: white none repeat scroll 0 0;
        background: transparent;
        // float: left;
        // font-size: 0.75em;
        font-size: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        border-radius: 100%;
        margin: 0.1em 1em;
        cursor: pointer;
    }

    .walCalendar>div.week>span.day.recoverable {
        background: var(--amarillo);
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.currentSession {
        background: var(--azul);
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.low-score {
        background-color: var(--morado);
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.notDone {
        background: var(--grisCalendario);
        color: #ff0000 !important;
    }

    .walCalendar>div.week>span.day.notDone2 {
        background: var(--verde);
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.isFailed {
        background: #ff0000;
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.finished {
        background: var(--verde) none repeat scroll 0 0 !important;
        color: var(--blanco) !important;
    }
    .walCalendar>div.week>span.day.finished.skipped {
        background: var(--fucsia) none repeat scroll 0 0 !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.started {
        background: var(--naranja) none repeat scroll 0 0;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.different-month {
        color: #c0c0c0 !important;
        // background: white none repeat scroll 0 0 !important;
        background: transparent !important;
    }

    .walCalendar>div.week>span.day.disabled-month {
        background: var(--gris) !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.finished.disabled-month {
        background: var(--verde) none repeat scroll 0 0 !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.isPast {
        color: #c0c0c0;
        background: white none repeat scroll 0 0;
    }

    .walCalendar>div.week>span.day.isCustomized {
        background: var(--amarillo) none repeat scroll 0 0 !important;
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.isExam {
        background: var(--verdeAzulado) none repeat scroll 0 0 !important;
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.isExaminControl {
        background: var(--verdeAzulado) none repeat scroll 0 0 !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.isControl {
        background: var(--morado) none repeat scroll 0 0 !important;
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.isControlinExam {
        background: var(--morado) none repeat scroll 0 0 !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.isTest {
        background: var(--fucsia) none repeat scroll 0 0 !important;
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.isDone {
        background-color: var(--verde) !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.isPending {
        background-color: var(--naranja) !important;
        color: var(--blanco) !important;
    }

    .walCalendar>div.week>span.day.isAvailable {
        background: #3ba2f4 none repeat scroll 0 0;
        color: var(--blanco);
    }

    .walCalendar>div.week>span.day.selected {
        border: 0.1em solid var(--gris);
    }

    .walCalendar>div.week.names>span {
        color: var(--azul);
        font-weight: bold;
        // text-transform: uppercase;
    }

    .calendario.dinamico {
        display: block;
        width: 100%;
        zoom: 1 !important;
        margin-bottom: 1em;
    }

    .calendario.dinamico.naranja {
        border: 0.1em solid var(--naranja);
    }

    .calendario.dinamico.azul {
        // border: 0.1em solid var(--azul);
    }

    .calendario.dinamico>div.header {
        font-size: 100%;
    }

    .calendario.dinamico.naranja>div.header {
        background: var(--naranja);
    }

    .calendario.dinamico.azul>div.header {
        background: var(--azul);
        width: 100.3%;
        margin-top: -0.2%;
        margin-left: -0.1%;
        height: auto;
    }

    .calendar-session>.calendario.dinamico.azul>div.header {
        background: var(--azul);
        width: 100.3%;
        margin-top: -0.2%;
        margin-left: -0.1%;
        height: 3.8em;
    }

    .calendario.dinamico>div.week {
        font-size: 100%;
        // margin: 0.2vh 0;
        margin: 0.05em 0;
        padding: 0 0.3rem;
    }

    .calendario.dinamico>div.week>span.day {
        // font-size: calc(var(--font-size) * 1.2);
        // width: 1.25em;
        // height: 1.25em;
        width: 1.8em;
        height: 1.8em;
        margin: 0.1em 0;
        // padding: 0.5em;
        color: var(--azulTexto);
    }

    .calendar-session>.calendario.dinamico>div.week>span.day {
        // font-size: calc(var(--font-size) * 1.2);
        width: 1.8em;
        height: 1.8em;
        margin: 0.1em 0;
        // padding: 0.5em;
        color: var(--azulTexto);
    }

    .calendar-session.calendar-pt>.calendario.dinamico>div.week>span.day {
        margin: 0.1em 0;
    }

    .calendario {
        background: var(--blanco);
        display: initial;
    }

    .calendario.sesion,
    .calendario.dictado,
    .calendario.reading,
    .calendario.hearing,
    .calendario.deduction,
    .calendario.hearing {
        width: 100%;
        margin: auto;
        border: 0;
        height: 80%;
        // font-size: var(--font-size);
    }

    .calendario.sesion>div.header,
    .calendario.dictado>div.header,
    .calendario.reading>div.header,
    .calendario.deduction>div.header,
    .calendario.hearing>div.header {
        background: var(--naranja);
        // width: 100.2%;
        // margin-top: -0.1%;
        // margin-left: -0.1%;
        margin-bottom: 0.7rem;
    }

    .calendario.sesion>div.header>span,
    .calendario.dictado>div.header>span,
    .calendario.reading>div.header>span,
    .calendario.deduction>div.header>span,
    .calendario.hearing>div.header>span {
        // font-size: 3vh;
        font-size: 120%;
    }

    .calendario.sesion>div.week,
    .calendario.dictado>div.week,
    .calendario.reading>div.week,
    .calendario.deduction>div.week,
    .calendario.hearing>div.week {
        margin: 0.5rem 0;
    }

    .calendario.sesion>div.week>span.day,
    .calendario.dictado>div.week>span.day,
    .calendario.reading>div.week>span.day,
    .calendario.deduction>div.week>span.day,
    .calendario.hearing>div.week>span.day {
        // font-size: 1.1em;
        font-size: 120%;
        width: 1.8em;
        height: 1.8em;
        margin: 0.1em;
        color: var(--gris);
        font-weight: bolder;
    }

    .calendario.dictado>div.header {
        background: var(--amarillo);
    }

    .calendario.reading>div.header {
        background: var(--morado);
    }

    .calendario.deduction>div.header {
        background: #3bb39c;
    }
    .calendario.hearing > div.header {
        background: var(--fucsia);
    }


    .calendario.dictado>div.week>span.day.today,
    .calendario.reading.sixWeeks>div.week>span.day.today,
    .calendario.deduction.sixWeeks>div.week>span.day.today,
    .calendario.hearing.sixWeeks>div.week>span.day.today {
        background-color: var(--azul);
        color: var(--blanco);
    }

    .calendario.peque {
        width: 100% !important;
        zoom: 0.45;
        border: 0.1em solid var(--azul);
    }

    .calendario.peque>div.header {
        background: var(--azul);
        font-size: 1.3em;
    }

    .calendario.peque>div.week>span.day {
        font-size: 1.8em;
        width: 2em;
        height: 2em;
        margin: 0;
    }

    .calendario.peque>div.week {
        margin: 1em 0;
    }
}

.walinwa-app.mobile {
    router-outlet+* {
        width: 100%;
    }

    .walinwa-container-sidebar {
        padding: calc(var(--font-size) * 0.5) !important;

        .walinwa-sidebar {
            flex-direction: row;
            min-height: auto;
            margin-bottom: calc(var(--font-size) * 0.5);
            margin-right: 0;
            width: 100%;
            overflow-x: auto;
            padding: calc(var(--font-size) * 0.5);
        }

        .walinwa-box {
            margin-bottom: 0 !important;
        }

        .walinwa-box-with-tabs {
            margin-top: calc(var(--font-size) * 2);

            .walinwa-box {
                min-height: calc(var(--font-size) * 38);
            }
        }
    }

    .walinwa-container {
        flex-direction: column;
        // justify-content: flex-start;
        justify-content: center;
        padding: 1em;

        .walinwa-box {
            margin-bottom: 0.5em;

            .walinwa-box-content {
                .walinwa-row {
                    flex-direction: column !important;
                    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
                    .mat-radio-label-content {
                        white-space: break-spaces;
                    }
                }

                .walinwa-scroll-container {
                    overflow-y: inherit !important;
                    max-height: 100% !important;
                }

                .walinwa-row>* {
                    width: 100% !important;
                }

                button {
                    margin-top: 1em;
                }

                button:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }

    .two-boxes {
        .walinwa-box {
            width: 50%;
        }
    }
}

@media only screen and (max-device-width: 1430px) and (min-device-width: 1180px) {
    .mat-mdc-dialog-container {
        max-width: 42vw !important;
    }
}

@media only screen and (max-device-width: 1179px) and (min-device-width: 1000px) {
    .mat-mdc-dialog-container {
        max-width: 46vw !important;
    }
}

@media only screen and (max-device-width: 999px) and (min-device-width: 880px) {
    .mat-mdc-dialog-container {
        max-width: 50vw !important;
    }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
    .calendars-row {
        flex-direction: column !important;
        width: 100%;
        align-items: center;

        .calendar-row {
            width: 100% !important;
        }
    }

    .walinwa-row {
        .calendar-row {
            width: 65% !important;
            margin: 0 1em 0 0 !important;
        }
    }

}

@media (orientation: portrait) {
    .modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal.medal-1 {
        height: 8em !important;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal.medal-2 {
        height: 7em !important;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal.medal-3 {
        height: 6em !important;
    }

    .footer-student {
        min-height: unset !important;
    }

    #challenge-textarea {
        height: 9em !important;
    }

    #student-report-calendar {
        width: 100%;
    }

    .header-student {
        overflow-x: auto;
    }

    .header-student .walinwa-header-user-info-secondary {
        width: 8em !important;
    }

    .header-student .walinwa-header-buttons {
        padding: 0 1em;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word {
        font-size: 1.25em !important;
    }

    .modal-tour-word-step-2 .btn-reclamar {
        width: 7em !important;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .row {
        padding: 0 0.5em !important;
        align-items: center;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .row .col6 {
        min-height: 40vh !important;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward {
        font-size: 0.75em !important;
    }

    .modal-tour-word-step-2 .mdl2 .contenido .tour-word-button {
        padding: 1em 3em 2em 3em !important;
    }

    .walinwa-app.mobile {
        vertical-layout-1 #main>.container>.container>.container {
            background-color: var(--blanco);
        }

        .f-1 {
            width: 100%;
        }

        .two-boxes {
            .walinwa-box {
                width: 90%;
            }
        }


        .walinwa-box-with-menu {
            flex-direction: column-reverse;
            height: auto !important;

            .walinwa-box {
                width: 100% !important;

                .walinwa-box-header {
                    flex-direction: column;
                }

                .walinwa-box-header>* {
                    margin-bottom: 1em;
                }
            }

            .walinwa-box-menu {
                margin: 0 0 1em 0 !important;
                height: 10em !important;
                min-height: 10em !important;
                display: flex;
                width: 100% !important;
                overflow-x: auto;
                align-items: center;

                .walinwa-box-menu-item {
                    min-width: 10em;
                }

                .walinwa-box-menu-button {
                    position: static !important;
                    min-width: 15em !important;
                    max-height: 5em !important;
                }
            }
        }

        .walinwa-header {
            margin-bottom: 2em;

            .walinwa-header-container {
                padding: 1em;

                .walinwa-header-logo {
                    height: 5.3em;
                }
            }
        }

        .walinwa-footer {
            .walinwa-footer-container {
                .f-1 {
                    width: 33% !important;

                    .submit-button img {
                        width: 2.5em;
                        height: 2.5em;
                        // font-size: calc(var(--font-size) * 1.1);
                    }
                }

                .studentf-1 {
                    width: 25% !important;
                }

                .exit {
                    width: 2.5em !important;

                    .secondary-text {
                        display: block !important;
                    }
                }

                .submit-button {
                    margin: 0.5em auto;

                    p {
                        display: none;
                    }
                }

                .go-back {
                    width: 2.5em !important;
                }

                .walinwa-switch {
                    width: 70% !important;
                    margin: 0.5em auto;
                    justify-content: center;
                }

                a {
                    width: 70% !important;
                    margin: 0.5em auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .modify-student-data {
            height: auto !important;
        }

        .calendario {
            .week {
                .day {
                    padding: 0 !important;
                }
            }
        }

        .calendars-row {
            flex-direction: column !important;
            width: 100%;
            align-items: center;

            .calendar-row {
                width: 100% !important;
            }
        }
    }

    body.theme-default .mat-mdc-dialog-container {
        width: 45vw !important;
        max-width: 45vw !important;
    }

    .content-dialog {
        .mat-mdc-dialog-actions {
            position: relative;
            width: 100%;

            button {
                width: 100% !important;
            }
        }
        }

    .content-dialog.white {
        .mat-mdc-dialog-actions {
            position: relative;

            button {
                width: 100% !important;
                height: unset !important;
                min-height: 1.8em !important;
                margin: 0.5em 0 !important;

                span {
                    white-space: break-spaces;
                }
            }
        }
    }

    @media only screen and (max-device-width: 620px) {
        body.theme-default .mat-mdc-dialog-container {
            width: 80vw !important;
            max-width: 80vw !important;
        }

        .walinwa-box-modal-reading {
            left: 2em !important;
            padding: 5em 4em 1em 4em !important;
        }

        .walinwa-app .walinwa-header .walinwa-header-container .coins-plank .coinsInfo {
            right: -15% !important;
        }

        .presentReward {
            height: 92% !important;
        }

        .presentReward h1 {
            margin-bottom: 0 !important;
        }

        .presentReward h3 {
            line-height: 1.3;
        }

        .reading-text-content .reading-text-answers .reading-text-answer input {
            margin-right: 0.5em;
            margin-right: 0.5em;
            width: unset !important;
            height: unset !important;
        }

        .perseverance-item img {
            width: 8em !important;
        }
    }

    app-games .controlsCiv {
        height: 32% !important;
    }

    .walinwa-box-modal-reading {
        left: 2em !important;
    }
}


app-demand {
    display: flex;
    flex-direction: column;
}

.italic-lighter {
    font-style: italic;
    font-weight: lighter !important;
    position: relative !important;
}

.italic {
    font-style: italic;
}

app-challenge {
    font-size: var(--font-size);

    .walinwa-challenge-slider {
        background-color: var(--verdeAzulado);
        color: var(--blanco);
        overflow-y: auto;
        justify-content: center;
        align-items: center;
        // padding: 2em;

        span {
            display: block;
            min-height: 5em;
            // font-size: calc(var(--font-size) * 1.25);
        }
    }

    .walinwa-challenge-tabs {
        display: flex;
        flex-wrap: wrap-reverse;

        .walinwa-challenge-tab {
            background-color: var(--blanco);
            border: 0.01em solid var(--grisClaro);
            border-bottom: none;
            color: var(--gris);
            padding: 0 1em;
            font-size: 1.25em;
            margin: 0 0.05em;
        }

        .walinwa-challenge-tab.active {
            background-color: var(--naranja);
            border: 0.01em solid var(--naranja);
            border-bottom: none;
            color: var(--blanco);
        }
    }


    .boton.grande.dorado {
        height: 2.5em;
        width: 15em;
        border-radius: 5em;
        font-size: var(--font-size);
        font-family: Ubuntu;
        padding-bottom: 0.4vh;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--gris);
        background: var(--amarillo);
        cursor: pointer;
    }

    .scrollButtonUp {
        position: absolute;
        top: 4.5vh;
        right: 1%;
        color: #a0b2bc;
    }

    .scrollButtonDown {
        position: absolute;
        bottom: 2vh;
        right: 1%;
        color: #a0b2bc;
    }

    .itemsContainer {
        width: 100%;
        height: 100%;
    }

    .tabContainer {
        width: 100%;
        height: 5%;
    }

    .lista_usuarios_to {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        margin: 2em auto;
        position: absolute;
        width: 24em;
        height: 12em;
        padding: 1em;
        left: 50%;
        top: 50%;
        margin-left: -12em;
        margin-top: -6em;
        border: 0.1em solid #3ba2f4;
        background: #eef2f4;
        border-radius: 20px;
    }

    .botones {
        width: 100%;
        display: flex;
        justify-content: space-around;
        display: inline-flex;
        padding-top: 3vh;
    }

    .retoTableContainer {
        height: 50%;
        width: 87%;
        margin: 0 3em;
        overflow-y: auto;
    }

    .retosTableContainer {
        height: 90%;
    }

    .footer {
        bottom: 1em;
        width: 100%;
    }

    .confirmacion {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        margin: 2em auto;
        position: absolute;
        width: 28em;
        height: 14em;
        background: var(--blanco);
        left: 50%;
        top: 50%;
        margin-left: -14em;
        margin-top: -7em;
        border: 0.1em solid #3ba2f4;
        background: #eef2f4;
        border-radius: 20px;
        padding: 1em;
    }

    .lista_usuarios_to_item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 3em;
    }

    .nav-tabs>li {
        margin-bottom: -0.1vh !important;
        /* width: 10em; */
        text-align: center;
    }

    .nav-tabs>li>a,
    .nav-tabs>li>a:hover {
        /* padding: 0; */
        padding: 0 1em;
        color: var(--gris);
        border-bottom: 0;
        background: var(--blanco);
        font-size: 1em;
        border-radius: 0 !important;
    }

    .nav-tabs>li>a:hover {
        cursor: pointer !important;
    }

    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:hover {
        background: var(--naranja);
        color: var(--blanco);
    }

    .fondoReto {
    }

    .bgContainerReto {
        width: 100%;
        height: 93%;
        background-size: contain;
        -webkit-border-radius: 0px 3px 3px 3px;
        -moz-border-radius: 0px 3px 3px 3px;
        border-radius: 0px 3px 3px 3px;
        border-bottom: 0.1vh solid #ddd;
        border-left: 0.1vh solid #ddd;
        border-right: 0.1vh solid #ddd;
        border-top: none;
        color: #555;
        position: relative;
    }

    .text {
        font-size: var(--font-size);
    }

    .priceText {
        white-space: nowrap;
    }

    .presentText {
        font-style: italic;
        font-size: 1.8vh;
        color: #329c45;
        display: block;
    }

    .textShopContainer {
        margin-left: 6%;
        margin-top: 1vh;
    }

    .title {
        font-weight: bold;
        font-size: 2vh;
    }

    .textGreen {
        color: #329c45;
    }

    .textGray {
        color: #a0b2bc;
    }

    /* Style the tab */
    .tab {
        overflow: hidden;
        border: 0.2vh solid #033fa1;
        border-bottom: 0px;
        background-color: #f1f1f1;
    }

    /* Style the buttons that are used to open the tab content */
    .tablinks {
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        /* padding: 14px 16px; */
        transition: 0.3s;
        width: 15%;
        text-align: center;
        height: 100%;
        padding: 1%;
    }

    /* Create an active/current tablink class */
    .tabActive {
        background-color: #ccc;
    }

    /* Style the tab content */
    .tabcontent {}

    .curso {
        margin: 0 auto;
    }

    .conteAlumnos {
        border-color: var(--naranja);
        color: #555;
        padding: 1%;
        width: 100%;
        height: 13em;
        overflow: auto;
        margin-top: 3%;
        border-style: solid;
        border-width: 1px;
        margin: 0 auto;
        background: var(--blanco);
    }

    .listAlumnos {
        display: flex;
        flex-direction: column;
    }

    .listAlumnosItem {
        display: flex;
        align-items: center;
        height: 2em;
    }

    .temas {
        border-color: var(--negro);
        width: 80%;
        position: relative;
        display: block;
        margin: 1em 3em;
    }

    input[type="checkbox"] {
        width: 1em;
        margin: 1em;
    }

    .temaItem {
        display: inline-block;
        align-items: center;
        height: 2em;
        padding: 0.8em;
        color: #555;
    }

    .coinText {
        width: 2em;
        /* height: 2em; */
        margin-top: -1vh;
    }

    .tablaAlumnos {
        width: 38em;
    }

    .tituloPendiente {
        padding: 0.1em;
        text-align: center;
        color: var(--blanco);
        background: var(--amarillo);
        font-size: 1.3em;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1em;
    }

    .tituloParticipantes {
        padding: 2%;
    }

    .tituloTema {
        margin: 1em 3em;
    }

    .tituloTemaOrganizador {
        margin: 1em 3em;
    }

    .temaReto {
        text-transform: uppercase;
        // display: inline-block;
    }

    .temaError {
        border: 1px solid #ff0000;
    }

    .stdError {
        border: 2px solid #ff0000;
    }

    .message_container {
        background-color: #fdfdfd;
        border-radius: 0.5em;
        flex-direction: column;
        justify-content: initial;
        margin: 1em 5em;
        /* max-width: 800px; */
        /* width: 75%; */
        padding: 1% 0;
        font-size: 0.9em;
        color: #033fa1;
        text-align: center;
        height: 10vh;
    }

    @media only screen {
        .message_container {
            webkit-box-shadow: 0 0 0 1.5px #033fa1;
            -moz-box-shadow: 0 0 0 1.5px #033fa1;
            box-shadow: 0 0 0 1.5px #033fa1;
        }
    }

    @media only screen and (min-width: 801px) and (min-height: 600px) {
        .message_container {
            webkit-box-shadow: 0 0 0 2px #033fa1;
            -moz-box-shadow: 0 0 0 2px #033fa1;
            box-shadow: 0 0 0 2px #033fa1;
        }
    }

    @media only screen and (min-width: 1281px) and (min-height: 901px) {
        .message_container {
            webkit-box-shadow: 0 0 0 2.5px #033fa1;
            -moz-box-shadow: 0 0 0 2.5px #033fa1;
            box-shadow: 0 0 0 2.5px #033fa1;
        }
    }

    .carousel {}

    .carousel-control {
        display: none;
    }

    .carousel-indicators {
        display: none;
    }

    .carousel-inner>.item {
        /*padding:0 3%;*/
    }
}



//tournament
.modal-tour-word-step-1 {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    font-size: var(--font-size);
}

.modal-tour-word-step-1 .mdl2 {
    width: 100%;
    height: 100%;
    position: relative;
    border: 0.2em solid var(--verde);
}

.modal-tour-word-step-1 .mdl2 .close-icon {
    position: absolute;
    width: 3em;
    height: 3em;
    top: -0.5em;
    right: -3em;
    z-index: 9999;
    cursor: pointer;
}

.modal-tour-word-step-1 .mdl2 .header {
    background-color: var(--verde);
    color: var(--blanco);
    text-align: center;
    font-size: 1.8em;
    padding: 0.25em 0;
    position: relative;
    font-weight: bold;
    margin-top: -0.1em;
    width: 100.1%;
}

.modal-tour-word-step-1 .mdl2 .contenido {
    height: 90% !important;
}

.modal-tour-word-step-1 .mdl2 .contenido .row {
    height: 100% !important;
    display: flex;
}

.modal-tour-word-step-1 .mdl2 .contenido .row .col1 {
    height: 100%;
    width: 45%;
}

.modal-tour-word-step-1 .mdl2 .contenido .row .col2 {
    height: 100%;
    width: 55%;
}

.modal-tour-word-step-1 .mdl2 .contenido .row>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0;
    height: 100% !important;
}

.modal-tour-word-step-1 .walin_tp {
    height: 100%;
    object-fit: contain;
}

.modal-tour-word-step-1 .title {
    color: var(--azul);
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.25em;
}

.modal-tour-word-step-1 .text {
    flex: 1;
    text-align: center;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
    // font-size: calc(var(--font-size) * 1.25);
    padding: 1.5em 0;
}

.modal-tour-word-step-1 .boton {
    margin-top: 1em !important;
    white-space: nowrap;
    cursor: pointer;
}

.modal-tour-word-step-2 .btn-reclamar {
    background-color: var(--blanco);
    text-align: center;
    color: var(--gris);
    border-radius: 5em;
    box-shadow: none !important;
    // font-size: calc(var(--font-size) * 1.3) !important;
    min-width: auto !important;
    width: 12em;
    height: 1.8em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    cursor: pointer;
}

.modal-tour-word-step-2 {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    font-size: var(--font-size);
}

.modal-tour-word-step-2 .mdl2 {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 0.2em solid var(--verde);
}

.modal-tour-word-step-2 .mdl2 .close-icon {
    position: absolute;
    width: 3em;
    height: 3em;
    top: -1.8em;
    right: -3em;
    z-index: 9999;
    cursor: pointer;
}

.modal-tour-word-step-2 .mdl2 .header {
    background-color: var(--verde);
    color: var(--blanco);
    text-align: center;
    font-size: 1.8em;
    // padding: 0.10em 0;
    position: relative;
    font-weight: bold;
    margin-top: -0.1em;
    width: 100.1%;
    height: 2.84em;
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-tooltip-phase {
    position: relative;
    width: 1em;
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-info {
    position: absolute;
    right: 2%;
    top: 20%;
    height: 60%;
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-info-tooltip {
    width: 20rem;
    position: absolute;
    right: 14.4em;
    top: 1em;
    z-index: 1;
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-info-tooltip .pico {
    background: var(--blanco);
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.5em;
    right: -0.5em;
    transform: rotateZ(45deg);
    border: 0;
    border-right: 0.1rem solid var(--verde);
    border-top: 0.1rem solid var(--verde);
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-info-tooltip .caja {
    background: var(--blanco);
    width: 100%;
    height: 100%;
    font-size: 70%;
    padding: 1rem;
    border: 0.1em solid var(--verde);
    color: var(--negro);
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-info-tooltip .caja .titletour {
    color: var(--azul);
    font-weight: bold;
    margin-bottom: 1rem;
    // font-size: calc(var(--font-size) * 1.25);
}

.modal-tour-word-step-2 .mdl2 .header .mdl2-header-info-tooltip .caja .text {
    font-weight: lighter;
    // font-size: calc(var(--font-size) * 1.25);
}

.modal-tour-word-step-2 .mdl2 .contenido {
    flex: 1;
    height: 90%;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 37%;
    padding: 0em 3em 0 3em;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word.can-drop {
    font-weight: bold;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word {
    background-color: var(--verde);
    color: var(--blanco);
    text-align: center;
    font-size: 1.8em;
    width: 30%;
    padding: 0.25em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-info-tooltip {
    width: 20rem;
    position: absolute;
    right: -2rem;
    top: 3rem;
    z-index: 1;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-info-tooltip .pico {
    background: var(--blanco);
    width: 1em;
    height: 1em;
    position: absolute;
    top: -0.8rem;
    right: 2rem;
    transform: rotateZ(-45deg);
    border: 0;
    border-right: 0.1rem solid var(--verde);
    border-top: 0.1rem solid var(--verde);
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-info-tooltip .caja {
    background: var(--blanco);
    width: 100%;
    height: 100%;
    font-size: 70%;
    padding: 1rem;
    border: 0.1em solid var(--verde);
    color: var(--negro);
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-info-tooltip .caja .definition {
    color: var(--gris);
    // font-size: calc(var(--font-size) * 1.25);
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word .tour-word-info {
    width: 1em;
    height: 1em;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word.Gold {
    background-color: #ffc600;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word.Silver {
    background-color: #bdd3da;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-words .tour-word.Bronze {
    background-color: #dd926c;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-medals {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0em 5em 1em 5em;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-medals .tour-word-medal {
    margin: 0 2em;
    width: 15%;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-medals-info {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4%;
    font-size: 1em;
    color: var(--azul);
}

.modal-tour-word-step-2 .mdl2 .contenido .row {
    display: flex;
    // font-size: calc(var(--font-size) * 1.2);
    padding: 0 2em;
    color: var(--gris);
    height: 81%;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 {
    height: 100%;
    min-height: 50vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .titletour {
    font-weight: bold;
    margin-bottom: 2em;
    margin-left: 1em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals .medal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
    width: 7em;
    position: relative;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium {
    align-items: flex-end;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal {
    background-size: cover;
    justify-content: space-between;
    position: relative;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal.medal-1 {
    height: 5em;
    width: 18em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal.medal-2 {
    height: 4em;
    width: 18em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal.medal-3 {
    height: 3em;
    width: 18em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal img {
    margin-top: -25%;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals.medals-with-podium .medal span {
    position: absolute;
    top: 110%;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals .medal img {
    height: 10vh;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col6 .medals .medal span {
    height: 25%;
    text-align: center;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .no-reward {
    background-color: var(--naranja);
    color: var(--blanco);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 79%;
    margin-top: 3em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .no-reward span {
    font-size: 1.8em;
    font-weight: bold;
    width: 4em;
    text-align: center;
    height: 12em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward {
    background-color: var(--naranja);
    color: var(--blanco);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2.5em 0 0rem 0;
    width: 85%;
    height: 79%;
    margin-top: 3em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward .text {
    font-size: 2em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward .walinwos-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward .walinwos {
    font-size: 10em;
    font-weight: bold;
    margin-top: -0.2em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward .walinwos-text .text {
    margin-top: -1.1em;
}

.modal-tour-word-step-2 .mdl2 .contenido .row .col4 .reward .boton {
    background-color: var(--blanco);
    color: var(--gris);
    margin-top: 2em !important;
    margin-bottom: 2em !important;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-button {
    padding: 6em 3em 2em 3em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-button-2 {
    padding: 2.2em 3em 2em 3em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-word-button .boton {
    cursor: pointer;
    width: 35%;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 1em 2em 1em;
    font-size: var(--font-size);
    height: 26.6em;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking .tour-ranking-position {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    margin: 0 1em;
    position: relative;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking .tour-ranking-position.tour-ranking-position-1 {
    height: 60%;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking .tour-ranking-position.tour-ranking-position-2 {
    height: 50%;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking .tour-ranking-position.tour-ranking-position-3 {
    height: 40%;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking .tour-ranking-position .tour-ranking-medal {
    width: 50%;
    margin-top: -20%;
}

.modal-tour-word-step-2 .mdl2 .contenido .tour-ranking .tour-ranking-position .tour-ranking-word {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 1.25em;
    color: var(--gris);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-end;
    height: 43vh;
    /* margin: 20% 0 0 0; */
    padding: 5% 0;
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day {
    width: 30%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 1.5%;
    padding: 2em 1em 2em 0;
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day .day {
    color: var(--gris);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day .week-ranking-word {
    display: flex;
    align-items: center;
    height: 3em;
    position: relative;
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day:nth-of-type(1) .week-ranking-word {
    background-color: var(--verdeAzulado);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day:nth-of-type(2) .week-ranking-word {
    background-color: var(--naranja);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day:nth-of-type(3) .week-ranking-word {
    background-color: var(--morado);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day:nth-of-type(4) .week-ranking-word {
    background-color: var(--fucsia);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day:nth-of-type(5) .week-ranking-word {
    background-color: var(--verde);
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day .week-ranking-word .week-ranking-word-medal {
    width: 3em;
    margin-left: -1em;
    margin-right: 1em;
}

.modal-tour-word-step-2 .mdl2 .contenido .week-ranking .week-ranking-day .week-ranking-word .word {
    color: var(--blanco);
    font-size: 1.25em;
}



app-demand {
    .m {
        width: 100%;
        height: 100%;
    }

    fieldset {
        border: 0.05em solid var(--gris);
        padding: 2% 3%;
        width: 100%;
    }

    header .botones {
        display: flex;
    }

    legend {
        display: block;
        margin: 0;
        font-size: 3vh;
        color: var(--gris);
        border: 0;
        width: auto;
    }

    .campo {
        margin-bottom: 0.5em;
    }

    .headerMenu {
        height: 100%;
        background-color: var(--gris);
        width: 123vh;
        margin: auto;
        z-index: 2;
    }

    .headerMenuBack {
        height: 15%;
        background-color: var(--gris);
        width: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        z-index: 1;
    }

    .leftMenuHeader {
        width: 25%;
        float: left;
        height: 100%;
    }

    .leftMenuHeader img {
        max-height: 75%;
        width: 46%;
        margin-top: 6%;
    }

    .contentHeader {
        width: 75%;
        position: relative;
        float: left;
        height: 100%;
        justify-content: flex-start;
        display: flex;
    }

    .menuLogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .welcomeContainer {
        height: 78%;
        width: 90%;
        margin: 3% auto;
        display: block;
        background-color: rgba(0, 0, 0, 0.07);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        position: relative;
    }

    .form-control {
        height: auto;
        padding: 1% 5%;
        font-size: 2vh !important;
    }

    .formArea {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 66%;
        padding: 2%;
        color: var(--azul);
    }

    .formArea input {
        border: 0;
        border-bottom: 0.1em solid var(--azul);
        color: var(--gris);
    }

    .formArea .checkbox {
        font-size: 1.5em !important;
    }

    .checkbox {
        font-size: 2.5em !important;
        margin: 3%;
    }

    .inputField {
        text-align: right;
        width: 45%;
    }

    .inputFieldSmall {
        width: 45%;
        float: left;
        margin: 0 5% 0 0;
        text-align: right;
    }

    input {
        padding-right: 5%;
    }

    .jsonArea {
        overflow-x: hidden;
        overflow-y: auto;
        height: 30%;
        width: 95%;
        margin: 0;
        word-wrap: break-word;
        font-size: 2vh !important;
        background: var(--naranja);
        color: var(--blanco);
        padding: 2%;
    }

    .blueButton {
        margin: 5% 2%;
        min-width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26%;
    }

    /* Left DIV */
    .l {
        width: 140px;
        margin: 0px;
        padding: 0px;
        float: left;
        text-align: right;
    }

    /* Right DIV */
    .r {
        width: 300px;
        margin: 0px;
        padding: 0px;
        float: right;
        text-align: left;
    }

    .a {
        clear: both;
        width: 470px;
        padding: 10px;
    }

    .linea {
        margin: 3px;
    }

    @media only screen {
        .welcomeContainer {
            /* outer shadows  (note the rgba is red, green, blue, alpha) */
            -webkit-box-shadow: 0 0 0 1.5px #70c6eb, 0 0 0 3px var(--blanco);
            -moz-box-shadow: 0 0 0 1.5px #70c6eb, 0 0 0 3px var(--blanco);
            box-shadow: 0 0 0 1.5px #70c6eb, 0 0 0 3px var(--blanco);
        }

        .formBoxTitle {
            font-size: medium;
        }
    }

    @media only screen and (min-width: 801px) and (min-height: 600px) {
        .welcomeContainer {
            /* outer shadows  (note the rgba is red, green, blue, alpha) */
            -webkit-box-shadow: 0 0 0 2px #70c6eb, 0 0 0 4px var(--blanco);
            -moz-box-shadow: 0 0 0 2px #70c6eb, 0 0 0 4px var(--blanco);
            box-shadow: 0 0 0 2px #70c6eb, 0 0 0 4px var(--blanco);
        }

        .formBoxTitle {
            font-size: x-large;
        }
    }

    @media only screen and (min-width: 1281px) and (min-height: 901px) {
        .welcomeContainer {
            /* outer shadows  (note the rgba is red, green, blue, alpha) */
            -webkit-box-shadow: 0 0 0 2.5px #70c6eb, 0 0 0 5px var(--blanco);
            -moz-box-shadow: 0 0 0 2.5px #70c6eb, 0 0 0 5px var(--blanco);
            box-shadow: 0 0 0 2.5px #70c6eb, 0 0 0 5px var(--blanco);
        }

        .formBoxTitle {
            font-size: x-large;
        }
    }

    td table {
        width: 100%;
    }

    td table td {
        padding: 1%;
    }

    td table td:nth-of-type(1) {
        width: 40%;
    }

    td table td:nth-of-type(2) {
        width: 60%;
    }

    label {
        margin-bottom: 1%;
    }

    .form-group {
        margin-bottom: 5%;
    }
}


.slide-content {
    .walinwa-box-content {
        background-color: transparent !important;
    }
}

.p-i,
.p-i2 {
    i {
        font-family: "Ubuntu" !important;
    }
}

.esquina-inf-der-sm-mobile {
    height: 19em;
    width: 30em;
}

.walinwa-container-space {
    z-index: 1;
    justify-content: normal !important;
    flex-direction: column;
    width: 100% !important;
    padding: 0 !important;
}

.walinwa-container-space-sm-mobile {
    justify-content: start !important;
}

.walinwa-container-space-xs {
    justify-content: start !important;
}

div.walinwa-box.form-container-sm-mobile {
    width: 95% !important;
    margin-top: 3em;
    height: fit-content !important;
}

div.walinwa-box.form-container-md {
    width: 60% !important;
    margin-top: 2em;
    height: fit-content !important;
}

div.walinwa-box.form-container-lg-ipad {
    width: 58% !important;
    height: fit-content !important;
}

.esq-inf-dere {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 48vw;
}

div.walinwa-box.form-container-md-iPad {
    width: 61% !important;
    height: fit-content !important;
}

.doc-number {
    margin-left: -3%;
    position: absolute;
    margin-top: -4% !important;
}

.doc-number2 {
    margin-left: -3%;
    position: absolute;
    margin-top: -1.2% !important;
}

.walinwa-app {
    .walinwa-main {
        &.external-page {
            .walinwa-container {
                max-width: 100vw !important;

                @media (max-width: calc(#{$breakpoint-xs} + 1px)) {
                    --font-size: 9px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .modal-tour-word-step-2 .mdl2 .close-icon,
    .modal-tour-word-step-1 .mdl2 .close-icon{
        top: -3em;
        right: 0;
    }

    .modal-tour-word-step-2 .mdl2 .header .mdl2-header-info-tooltip {
        right: 8.4em;
        top: 1em;
    }

    
}

@media screen and (max-width: 599px) {

    .modal-tour-word-step-1 .walin_tp{
        height: 18rem;
    }

    .modal-tour-word-step-1 .text{
        justify-content: start;
        padding-top: 0;
    }

    .modal-tour-word-step-1 .mdl2 .contenido.welcome{
        height: auto;
    }
}

@media screen and (max-width: 390px) {
    .modal-tour-word-step-1 .walin_tp{
        height: 14rem;
    }
}

.cdk-overlay-pane {
    min-width: fit-content !important;
 }

dx-data-grid {
    font-family: 'Ubuntu' !important;
}

.gray-background {
    background-color: #F1F4F5 !important;    
}

.dx-datagrid .dx-data-row.dx-column-lines > td,
.dx-datagrid .dx-datagrid-filter-row.dx-column-lines > td {
    border-left: 0px !important;
    border-right: 0px !important;
}

.dx-header-row {
    background-color: var(--verdeAzulado) !important;    
    color: var(--blanco) !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

.dx-data-row {
    background-color: var(--blanco) !important;    
    color: var(--colorText) !important;
    font-weight: normal !important;
    font-size: 16px !important;
}

.dx-group-row {
    background-color: var(--blanco) !important;    
    color: var(--colorText) !important;
}

.dx-master-detail-cell {
    background-color: var(--blanco) !important;    
}

.dx-scrollable-container {
    background-color: var(--blanco) !important;    
}

i.dx-icon {
    font-family: 'DXIcons' !important;
}

.dx-prev-button::before, .dx-next-button::before {
    top: 100% !important;
}