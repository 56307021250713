// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Body scroll lock
// -----------------------------------------------------------------------------------------------------
html,
body {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

// -----------------------------------------------------------------------------------------------------
// @ Boxed body
// -----------------------------------------------------------------------------------------------------
body {

    // Boxed
    &.boxed {
        max-width: 1200px;
        margin: 0 auto;

        @include mat.elevation(8);
    }
}

/*----------------------------------------------------------------*/
/*  @ Text rendering & box sizing
/*----------------------------------------------------------------*/
* {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }

    // Remove focus outline
    &:focus {
        outline: none;
    }
}

a {
    color: var(--gris) !important;
    text-decoration: underline !important;
}

.text {
    color: var(--gris) !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Responsive images
// -----------------------------------------------------------------------------------------------------

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}

// -----------------------------------------------------------------------------------------------------
// @ Input
// -----------------------------------------------------------------------------------------------------
input {
    border: none;
    padding: 0 16px;
    color: var(--gris) !important;
}

.mat-mdc-input-element {
    color: var(--gris) !important;
}

.mat-form-field-invalid .mat-mdc-input-element {
    color: var(--gris) !important;
    // caret-color: #5E767D !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-underline {
    background-color: grey !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-ripple {
    background-color: grey !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-value{
    color: var(--gris) !important;
}
// -----------------------------------------------------------------------------------------------------
// @ Buttons
// -----------------------------------------------------------------------------------------------------

.submit-button {

    margin: 4% auto;
    height: 2em !important;
    width: 12em !important;
    // font-size: 15.7083px !important;
    padding-bottom: 0.4vh !important;
    align-self: flex-end;
    line-height: normal !important;
    box-shadow: none !important;
    font-weight: normal !important;

    @include media-breakpoint('xs') {
        width: 90%;

    }

}

.submit-button:hover {
    background-color: var(--gris) !important;
}

.submit-button-mobile {

    align-self: center;
}

button.mat-accent {
    // background-color: #0CA1F1!important;
}

// -----------------------------------------------------------------------------------------------------
// @ Privacy radio
// -----------------------------------------------------------------------------------------------------

.privacy {
    width: 100%;
}

// -----------------------------------------------------------------------------------------------------
// @ Header from 
// -----------------------------------------------------------------------------------------------------

.header {
    font-weight: bolder;
    color: var(--blanco);
    font-size: var(--font-size);
    padding: .5em 1em;
}

.header-alone {
    // font-size: 1.8em !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Wrapper form
// -----------------------------------------------------------------------------------------------------

.form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1 0 auto;
    padding: 1% !important;
    align-items: center;
    width: 55%;
    margin: 0 auto;
    margin-bottom: 20px;
    min-width: 90vh;
}

.form-wrapper-doble {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1 0 auto;
    padding: 1vh;
    align-items: center;
    margin: 0 auto;
}

.column-form {
    width: 100%;
    height: 100%;
    // flex-direction: column !important;
    // width: 90vmin !important;
    // margin-top: 10% !important;
    // min-width: 0px;
    // // width: 90vh !IMPORTANT;
    // margin: 0 auto !IMPORTANT;
    // margin-bottom: 2em !IMPORTANT;
}

.card-form {
    // width: 90%;
    height: inherit;
    // min-height: 40rem;
    text-align: center;
    overflow: hidden;
    // margin: auto 2%;
    // margin-bottom: 20px;
    border-style: solid;
    border: 0.1em solid var(--verde);
    min-height: 50vmin;
    display: flex;
    flex-direction: column;
    width: 80vmin;
}

.doble-card-form {
    width: 36rem;
    max-width: 36rem;
    // height: inherit;
    min-height: 40rem;
    text-align: center;
    overflow: hidden;
    margin: 0 2%;
    border-style: solid;
    border: 0.1em solid var(--verde);
}

.card-form-alone {
    // width: 100% !important;
}

.card-form-mobile {
    // min-height: 70rem !important;
}

.card-form-mobile-min {
    // min-height: 40rem !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Form
// -----------------------------------------------------------------------------------------------------

form { 
    font-size: var(--font-size);
    padding: 1em 2em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // display: flex;
    // flex-direction: column;
    // flex: 1 1 0;
    // width: 100%;
    // text-align: left;
    // height: inherit;
    // justify-content: space-between;
    // padding: 1em 2em;
    // min-height: inherit;
    // max-height: 100%;
    // padding-bottom: 3em;

    mat-form-field {
        width: 100%;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    mat-checkbox {
        margin: 0;
    }

    .submit-button {
        margin-top: 4%;
    }

    h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        font-size: 1.75vh;
        color: var(--gris);
        justify-content: flex-start;
        margin: 0 auto;
        margin-bottom: 2%;
    }

    h2.mobile {
        margin-bottom: 10% !important;
    }

}

.form-mobile {
    padding-bottom: 1em;
}

// -----------------------------------------------------------------------------------------------------
// @ Flex
// -----------------------------------------------------------------------------------------------------

.fx-row {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.fx-row-alone {
    // height: 20%;
}

.fx-row-mobile {
    flex-direction: column;
    justify-content: center;
}

.fx-row-mobile-last {
    // height: 18rem;
    // height: -webkit-fill-available;
    flex-direction: column;
    justify-content: flex-end;
}

.fx-row-footer {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 2%;
}

.fx-row-footer-mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fx-end {
    justify-content: flex-end;
    align-items: flex-end;
}

.fx-start {
    justify-content: flex-start;
    align-items: flex-start;
}

.fx-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
}

.fx-content-mobile {
    margin-top: 1vh;
}

// -----------------------------------------------------------------------------------------------------
// @ Height
// -----------------------------------------------------------------------------------------------------

.h10 {
    height: 12%;
}

.h20 {
    height: 20%;
}

.h30 {
    height: 30%;
}

.h40 {
    height: 30%;
}

.h50 {
    height: 50%;
}

.h70 {
    height: 70%;
}

// -----------------------------------------------------------------------------------------------------
// @ Width
// -----------------------------------------------------------------------------------------------------

.w70 {
    width: 70%;
}

.w30 {
    width: 30%;
}

// -----------------------------------------------------------------------------------------------------
// @ particular features
// -----------------------------------------------------------------------------------------------------

.tutor {
    background-color: var(--verde);
}