@use '@angular/material' as mat;
@mixin renewLlicense-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);

    renew-license {

        #renew-license {

            #renew-license-form-wrapper {

                #renew-license-form {
                    @if ($is-dark) {
                        background: mat.get-color-from-palette($fuse-navy, 600);
                    } @else {
                        background: map-get($background, card);
                    }

                    .separator {
                        color: map-get($foreground, divider);

                        .text {

                            &:before,
                            &:after {
                                border-top-color: map-get($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
    }
}